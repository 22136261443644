import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ResponsiveLine, Serie } from '@nivo/line';
import { LegenedProps } from '@nivo/bar/dist/nivo-bar.es';
import LoadingIndicator from 'src/shared/loading-indicator';
import { useDashboardContext } from 'src/shared/contexts';
import { NivoTheme } from 'src/theming/theme-nivo/nivo-theme-context';
import { PeriodValue } from '../common';
import { DashboardCard } from '../dashboard-card/dashboard-card';
import numericValueFormatter from '../common/numeric-value-formatter';
import {
    dashboardLineChartColors,
    dashboardLineChartTrendColor,
    dashboardLineChartTrendColorDark,
    getColorByKey,
} from './dashboard-line-chart-colors';
import { groupBy, orderBy } from 'lodash';
import i18n from 'src/i18-next.config';
import { DashedSolidLine } from './dashed-solid-line';
import { DashboardLineChartTooltip } from './dashboard-line-chart-tooltip';
import useDarkMode from 'use-dark-mode';

interface Props {
    nivoTheme: NivoTheme;
    editMode: boolean;
    years?: number[];
    isShown?: boolean;
    handleChangeShown?: (name: string, value: boolean) => void;
    name?: string;
    amounts: PeriodValue<number>[];
    trends: PeriodValue<number>[];
    title: string;
}

function getLineSettings(
    years: number[],
    trendColor: string
): {
    keys: string[];
    legened?: ({ dataFrom: 'indexes' | 'keys' } & LegenedProps)[];
    isInteractive: boolean;
} {
    const orderedYears = orderBy(years, (y) => y);
    const lastYear = orderedYears[orderedYears.length - 1];

    const legendsData = orderedYears.map((year, i) => ({
        id: year.toString(),
        label: year.toString(),
        color: dashboardLineChartColors[i],
    }));

    legendsData.push({
        id: 'yearTrend' + lastYear,
        label: lastYear + ' Trend',
        color: trendColor,
    });

    return {
        isInteractive: true,
        keys: years?.map((year) => `year${year}`),
        legened: [
            {
                data: legendsData,
                anchor: 'top',
                dataFrom: 'keys',
                direction: 'row',
                itemHeight: -50,
                itemWidth: 80,
                translateX: 0,
                symbolSize: 10,
            },
        ],
    };
}

function mapPeriodValuesToLineChartData(
    periodValues: PeriodValue<number>[],
    forcedColor?: string,
    keySuffix?: string,
    isDashed?: boolean
): Serie[] | undefined | null {
    if (!periodValues) {
        return undefined;
    }

    const valuesByYear = groupBy(periodValues, (pv) => pv.year);

    const series: Serie[] = [];
    let colorKey = 0;
    for (const yearKey in valuesByYear) {
        const color = forcedColor != null ? forcedColor : getColorByKey(colorKey);

        series.push({
            id: keySuffix == null ? yearKey : `${yearKey}${keySuffix}`,
            color: color,
            isDashed: isDashed,
            data: valuesByYear[yearKey].map((value) => {
                return {
                    x: new Date(2021, value.month - 1, 1).toLocaleString(i18n.language, {
                        month: 'short',
                    }),
                    y: value.value,
                };
            }),
        });
        ++colorKey;
    }

    return series;
}

export const DashboardDamageAverageByMonth: FC<Props> = ({
    nivoTheme,
    years,
    editMode,
    isShown,
    handleChangeShown,
    name,
    amounts,
    trends,
    title,
}): JSX.Element => {
    const { t } = useTranslation(['dashboard']);

    const { isGetDashboardDataLoading } = useDashboardContext();
    const darkMode = useDarkMode();

    const trendColor = darkMode.value
        ? dashboardLineChartTrendColorDark
        : dashboardLineChartTrendColor;

    const data: Serie[] = [];
    const periodValuesData = mapPeriodValuesToLineChartData(amounts);
    const trendData = mapPeriodValuesToLineChartData(trends, trendColor, ' Trend', true);
    if (trendData != null) {
        data.push(...trendData);
    }

    if (periodValuesData != null) {
        data.push(...periodValuesData);
    }

    const settings = getLineSettings(years, trendColor);

    return (
        <DashboardCard
            header={title}
            editMode={editMode}
            isShown={isShown}
            handleChangeShown={handleChangeShown}
            name={name}
        >
            <>
                {isGetDashboardDataLoading && <LoadingIndicator />}
                {!isGetDashboardDataLoading && data === null && (
                    <div
                        style={{
                            display: 'flex',
                            height: '100%',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                        }}
                    >
                        {t('emptyMsg')}
                    </div>
                )}
                {!isGetDashboardDataLoading && Array.isArray(data) && (
                    <ResponsiveLine
                        data={data}
                        curve='monotoneX'
                        enableSlices='x'
                        theme={nivoTheme.theme}
                        colors={(o) => o.color}
                        margin={{ top: 50, right: 30, bottom: 50, left: 60 }}
                        legends={settings.legened}
                        isInteractive={settings.isInteractive}
                        sliceTooltip={(props) => {
                            return (
                                <DashboardLineChartTooltip
                                    tooltipData={props}
                                    valueFormatter={numericValueFormatter}
                                    valueSuffix='€'
                                />
                            );
                        }}
                        axisLeft={{
                            legend: t('damageAverage.legend'),
                            legendPosition: 'middle',
                            legendOffset: -50,
                            format: numericValueFormatter,
                        }}
                        axisBottom={{
                            legend: t('month'),
                            legendPosition: 'middle',
                            legendOffset: 32,
                        }}
                        layers={[
                            'grid',
                            'markers',
                            'axes',
                            'areas',
                            'crosshair',
                            'slices',
                            'points',
                            'mesh',
                            'legends',
                            DashedSolidLine,
                        ]}
                    />
                )}
            </>
        </DashboardCard>
    );
};
