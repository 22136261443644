import { makeStyles, Theme } from '@material-ui/core';
import {
    isHectorInstance,
    isAonDigitalInstance,
    isAonMotorInstance,
    isHectorDemoInstance,
} from 'src/environment';
import { THEME_MODES } from 'src/theming';

import { isAonKompositInstance, isAonInstance } from 'src/environment';

export const useMaintanancePageStyles = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        maintanancePageContainer: {
            position: 'relative',
            height: '100vh',
            width: '100vw',
            backgroundColor: theme.palette.primary.main,
        },
        sgiContainer: {
            backgroundImage: `url('/static/images/sgi-maintanance-background.jpg')`,
            backgroundRepeat: 'no-repeat',
            '&:after': {
                position: 'absolute',
                height: '100vh',
                width: '100vw',
                content: '""',
                background: theme.palette.secondary.light,
            },
        },
        aonContainer: {
            backgroundImage: `url('static/images/aon-maintanance-background.png')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
        logoContainer: {
            position: 'absolute',
            left: 80,
            top: 60,
            zIndex: 10,
        },
        infoContainer: {
            width: 440,
            zIndex: 10,
        },
        errorNumber: {
            marginBottom: theme.spacing(4),
            fontSize: '4rem',
            fontWeight: theme.typography.fontWeightBold,
            color:
                (isAonInstance ||
                    isAonKompositInstance ||
                    isHectorDemoInstance ||
                    isHectorInstance ||
                    isAonDigitalInstance ||
                    isAonMotorInstance) &&
                !isDarkMode
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.contrastText,
        },
        message: {
            fontSize: '2rem',
            color:
                isAonInstance ||
                isAonKompositInstance ||
                isAonDigitalInstance ||
                isAonMotorInstance ||
                isHectorDemoInstance ||
                isHectorInstance
                    ? theme.palette.primary.contrastText
                    : theme.palette.secondary.contrastText,
        },
        iconContainer: {
            width: 150,
            height: 140,
            '& svg': {
                color: theme.palette.secondary.main,
            },
        },
    };
});
