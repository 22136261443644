import { makeStyles, Theme } from '@material-ui/core/styles';

import { THEME_MODES } from 'src/theming';
import { LIGHT_SGIFFOXX_THEME_COLORS } from 'src/theming/themes/sgiffoxx';
import { LIGHT_HECTOR_THEME_COLORS } from 'src/theming/themes/hector';
import {
    isHectorInstance,
    isCeatInstance,
    isSGIFFOXXKInstance,
    isAonDigitalInstance,
    isAonMotorInstance,
    isHectorDemoInstance,
    isCeatMaklerInstance,
} from 'src/environment';
import { DARK_AON_MOTOR_THEME_COLORS } from 'src/theming/themes/aon-motor';

export const useStyle = makeStyles((theme: Theme) => {
    const isDarkMode = theme.palette.type === THEME_MODES.DARK;
    if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
        return {
            formContainer: {
                display: 'flex',
                flexDirection: 'column',
            },
            formTitle: {
                marginBottom: theme.spacing(7),
                color: isDarkMode
                    ? theme.palette.primary.contrastText
                    : theme.palette.common.black,
                fontSize: '2.7rem',
                [theme.breakpoints.down('lg')]: {
                    fontSize: '2.3rem',
                    marginBottom: theme.spacing(5),
                },
                [theme.breakpoints.down('md')]: {
                    fontSize: '2rem',
                    marginBottom: theme.spacing(3),
                },
            },
            formMessage: {
                marginBottom: theme.spacing(5),
                color: isDarkMode
                    ? theme.palette.primary.contrastText
                    : theme.palette.common.black,
                fontSize: '2.4rem',
                [theme.breakpoints.down('lg')]: {
                    fontSize: '2rem',
                    marginBottom: theme.spacing(4),
                },
                [theme.breakpoints.down('md')]: {
                    fontSize: '1.8rem',
                    marginBottom: theme.spacing(3),
                },
            },
            submitButton: {
                width: 260,
                height: 50,
                alignSelf: 'flex-end',
                margin: `${theme.spacing(6)}px 0`,
                fontWeight: theme.typography.fontWeightBold,
                backgroundColor: isDarkMode
                    ? 'rgba(0, 0, 0, 0)'
                    : theme.palette.secondary.dark,
                color: isDarkMode
                    ? theme.palette.secondary.contrastText
                    : theme.palette.info.contrastText,
                borderRadius: 0,
                border: isDarkMode
                    ? `1px solid ${theme.palette.secondary.contrastText}`
                    : 'none',
                '&:hover': {
                    backgroundColor: isDarkMode
                        ? theme.palette.secondary.dark
                        : theme.palette.primary.light,
                    color: isDarkMode
                        ? theme.palette.secondary.contrastText
                        : theme.palette.secondary.dark,
                    border: `1px solid ${
                        isDarkMode
                            ? theme.palette.secondary.contrastText
                            : theme.palette.secondary.dark
                    }`,
                },
                [theme.breakpoints.down('md')]: {
                    height: 40,
                    margin: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px 0`,
                },
            },
            goToLoginLink: {
                alignSelf: 'flex-end',
                fontSize: '1.4rem',
                '& a': {
                    color: isDarkMode
                        ? theme.palette.secondary.contrastText
                        : theme.palette.secondary.dark,
                },
            },
            strengthBar: {},
            forgotPasswordLink: {
                alignSelf: 'flex-end',
                color: isDarkMode
                    ? theme.palette.secondary.contrastText
                    : theme.palette.secondary.dark,
            },
        };
    }
    if (isSGIFFOXXKInstance) {
        return {
            formContainer: {
                display: 'flex',
                flexDirection: 'column',
            },
            formTitle: {
                marginBottom: theme.spacing(3),
                color: theme.palette.primary.contrastText,
                fontSize: '2.7rem',
                [theme.breakpoints.down('lg')]: {
                    fontSize: '2.3rem',
                },
                [theme.breakpoints.down('md')]: {
                    fontSize: '2rem',
                },
            },
            formMessage: {
                fontSize: '2.4rem',
                marginBottom: theme.spacing(3),
                [theme.breakpoints.down('lg')]: {
                    marginBottom: theme.spacing(1),
                    fontSize: '2rem',
                },
                [theme.breakpoints.down('md')]: {
                    marginBottom: theme.spacing(1),
                    fontSize: '1.8rem',
                },
            },
            submitButton: {
                width: 260,
                height: 50,
                alignSelf: 'flex-end',
                margin: `${theme.spacing(6)}px 0`,
                fontWeight: theme.typography.fontWeightBold,
                backgroundColor: isDarkMode
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.main,
                color: isDarkMode
                    ? theme.palette.secondary.contrastText
                    : theme.palette.info.contrastText,
                border: isDarkMode
                    ? `1px solid ${theme.palette.secondary.contrastText}`
                    : 'none',
                borderRadius: 5,
                '&:hover': {
                    backgroundColor: isDarkMode
                        ? theme.palette.secondary.contrastText
                        : theme.palette.primary.light,
                    color: theme.palette.secondary.main,
                    border: `1px solid ${
                        isDarkMode
                            ? theme.palette.secondary.contrastText
                            : theme.palette.secondary.main
                    }`,
                },
                [theme.breakpoints.down('md')]: {
                    height: 40,
                    margin: `${theme.spacing(2)}px 0 0 0`,
                },
            },
            goToLoginLink: {
                alignSelf: 'flex-end',
                fontSize: '1.4rem',
                fontWeight: theme.typography.fontWeightBold,
                '& a': {
                    color: isDarkMode
                        ? theme.palette.secondary.contrastText
                        : theme.palette.info.main,
                },
            },
            strengthBar: {
                fontSize: '1.4rem',
                '& li': {
                    color: theme.palette.primary.contrastText,
                    opacity: 0.6,
                },
            },
            forgotPasswordLink: {
                alignSelf: 'flex-end',
                color: isDarkMode
                    ? theme.palette.secondary.contrastText
                    : theme.palette.info.main,
                fontWeight: theme.typography.fontWeightBold,
            },
        };
    }
    if (isCeatInstance || isCeatMaklerInstance) {
        return {
            formContainer: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            },
            formTitle: {
                textAlign: 'center',
                fontSize: '2.7rem',
                color: theme.palette.primary.contrastText,
            },
            formMessage: {
                fontSize: '2.4rem',
                textAlign: 'center',
                padding: `${theme.spacing(7)}px 0`,
                color: theme.palette.primary.contrastText,
                [theme.breakpoints.down('md')]: {
                    padding: `${theme.spacing(3)}px 0`,
                },
            },
            submitButton: {
                width: 210,
                height: 40,
                marginTop: theme.spacing(4),
                boxSizing: 'border-box',
                backgroundColor: isDarkMode
                    ? `rgba(0, 0, 0, 0)`
                    : theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
                borderRadius: 0,
                border: isDarkMode
                    ? `1px solid ${theme.palette.secondary.contrastText}`
                    : 'none',
                '&:hover': {
                    backgroundColor: isDarkMode
                        ? theme.palette.common.white
                        : theme.palette.primary.main,
                    border: !isDarkMode
                        ? `1px solid ${theme.palette.secondary.main}`
                        : 'none',
                    color: theme.palette.secondary.main,
                },
                [theme.breakpoints.down('md')]: {
                    marginTop: theme.spacing(2),
                },
            },
            goToLoginLink: {
                fontSize: '1.6rem',
                marginTop: theme.spacing(4),
                '& a': {
                    color: isDarkMode
                        ? theme.palette.common.white
                        : theme.palette.info.main,
                },
            },
            strengthBar: {
                fontSize: '1.6rem',
                '& li': {
                    color: theme.palette.primary.contrastText,
                    opacity: 0.6,
                },
            },
            forgotPasswordLink: {
                fontSize: '1.6rem',
                marginTop: theme.spacing(4),
                color: isDarkMode ? theme.palette.common.white : theme.palette.info.main,
            },
        };
    }
    if (isHectorInstance) {
        return {
            formContainer: {},
            formTitle: {
                fontSize: 40,
                marginBottom: 20,
                color: '#0D3F67',
                fontWeight: theme.typography.fontWeightBold,
                [theme.breakpoints.down('lg')]: {
                    fontSize: 30,
                },
            },
            formMessage: {
                fontSize: 22,
                marginBottom: 60,
                color: '#0D3F67',
                [theme.breakpoints.down('xl')]: {
                    marginBottom: 40,
                },
                [theme.breakpoints.down('lg')]: {
                    marginBottom: 20,
                    fontSize: 18,
                },
            },
            submitButton: {
                display: 'flex',
                margin: '50px auto 40px auto',
                width: 360,
                borderRadius: 59,
                boxShadow: '0px 3px 6px #00000029',
                background:
                    'linear-gradient(0deg, rgba(83,97,218,1) 0%, rgba(35,213,208,1) 100%, rgba(35,213,208,1) 100%)',
                color: theme.palette.secondary.contrastText,
                transition: '0.3s',
                '&.Mui-disabled': {
                    background: 'rgba(0, 0, 0, 0.12)',
                },
                [theme.breakpoints.down('xl')]: {
                    margin: '40px auto 30px auto',
                },
                [theme.breakpoints.down('lg')]: {
                    margin: '30px auto 20px auto',
                },
                '&:hover': {
                    background: LIGHT_HECTOR_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
                    color: theme.palette.info.main,
                },
            },
            goToLoginLink: {
                display: 'block',
                width: 'fit-content',
                margin: '0 auto',
            },
            strengthBar: {},
            forgotPasswordLink: {
                display: 'block',
                width: 'fit-content',
                margin: '0 auto',
                color: '#0D3F67',
            },
        };
    }
    return {
        formContainer: {},
        formTitle: {
            marginBottom: theme.spacing(3),
            color: theme.palette.primary.contrastText,
        },
        formMessage: {
            opacity: 0.6,
            marginBottom: theme.spacing(3),
        },
        submitButton: {
            width: '100%',
            height: 40,
            margin: `${theme.spacing(6)}px 0`,
            backgroundColor: ({ isDarkMode }: { isDarkMode: boolean }) =>
                isDarkMode ? theme.palette.info.main : theme.palette.secondary.main,
            color: theme.palette.info.contrastText,
        },
        goToLoginLink: {
            fontSize: '1.4rem',
            '& a': {
                color: theme.palette.secondary.main,
            },
        },
        strengthBar: {
            fontSize: '1.4rem',
            '& li': {
                color: theme.palette.primary.contrastText,
                opacity: 0.6,
            },
        },
        forgotPasswordLink: {
            color: theme.palette.info.main,
        },
    };
});

export const useFormControlStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            margin: `${theme.spacing(2)}px 0`,
            '& label': {
                fontSize: '2.4rem',
            },
        },
    };
});

export const useFormControlLabelStyles = makeStyles((theme: Theme) => {
    const isDarkMode = theme.palette.type === THEME_MODES.DARK;
    if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
        return {
            root: {
                alignItems: 'flex-start',
                marginLeft: 0,
                marginRight: 0,
            },
            label: {
                fontSize: '1.8rem',
                marginBottom: theme.spacing(4),
                color: isDarkMode
                    ? theme.palette.primary.light
                    : DARK_AON_MOTOR_THEME_COLORS.PRIMARY_DARK_COLOR,
                [theme.breakpoints.down('lg')]: {
                    fontSize: '1.6rem',
                    marginBottom: theme.spacing(3),
                },
                [theme.breakpoints.down('md')]: {
                    fontSize: '1.6rem',
                    marginBottom: theme.spacing(1),
                },
            },
        };
    }
    if (isSGIFFOXXKInstance) {
        return {
            root: {
                alignItems: 'flex-start',
                marginLeft: 0,
                marginRight: 0,
            },
            label: {
                fontSize: '1.8rem',
                fontWeight: theme.typography.fontWeightBold,
                marginBottom: theme.spacing(4),
                [theme.breakpoints.down('md')]: {
                    fontSize: '1.6rem',
                    marginBottom: theme.spacing(1),
                },
            },
        };
    }
    if (isCeatInstance || isCeatMaklerInstance) {
        return {
            root: {
                alignItems: 'center',
                marginLeft: 0,
                marginRight: 0,
            },
            label: {
                fontSize: '1.8rem',
                marginBottom: theme.spacing(3),
                [theme.breakpoints.down('md')]: {
                    marginBottom: theme.spacing(1),
                },
            },
        };
    }
    if (isHectorInstance) {
        return {
            root: {
                alignItems: 'flex-start',
                marginLeft: 0,
                marginRight: 0,
            },
            label: {
                fontSize: '1.8rem',
                marginBottom: theme.spacing(2),
                color: '#0D3F67',
                [theme.breakpoints.down('md')]: {
                    marginBottom: theme.spacing(1),
                },
            },
        };
    }
    return {
        root: {
            alignItems: 'flex-start',
            margin: 0,
        },
        label: {
            fontSize: '1.4rem',
            opacity: 0.6,
        },
    };
});

export const useInputStyles = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
        return {
            root: {
                width: '100%',
                height: 50,
                backgroundColor: isDarkMode
                    ? 'rgba(0, 0, 0, 0)'
                    : theme.palette.common.white,
                borderRadius: 0,
                '& fieldset': {
                    borderRadius: 0,
                },
                [theme.breakpoints.down('md')]: {
                    height: 40,
                },
            },
            focused: {
                '& fieldset': {
                    borderColor: `${
                        isDarkMode
                            ? theme.palette.primary.light
                            : theme.palette.secondary.light
                    }!important`,
                },
            },
            input: {
                height: 50,
                boxSizing: 'border-box',
                color: theme.palette.primary.contrastText,
                fontSize: '1.8rem',
                '&::placeholder': {
                    color: theme.palette.primary.contrastText,
                    fontSize: '1.8rem',
                },
                '&:-internal-autofill-selected': {
                    boxShadow: `inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px ${theme.palette.primary.main}`,
                },
            },
        };
    }
    if (isSGIFFOXXKInstance) {
        return {
            root: {
                width: '100%',
                height: 50,
                backgroundColor: theme.palette.common.white,
                [theme.breakpoints.down('md')]: {
                    height: 40,
                },
            },
            focused: {
                '& fieldset': {
                    borderColor: `${theme.palette.secondary.light}!important`,
                },
            },
            input: {
                height: 50,
                boxSizing: 'border-box',
                color: theme.palette.secondary.main,
                fontSize: '1.8rem',
                '&::placeholder': {
                    color: theme.palette.grey['700'],
                    fontSize: '1.8rem',
                },
                '&:-internal-autofill-selected': {
                    boxShadow: `inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px ${
                        !isDarkMode
                            ? theme.palette.primary.main
                            : LIGHT_SGIFFOXX_THEME_COLORS.SECONDARY_MAIN_COLOR
                    }`,
                },
            },
        };
    }
    if (isCeatInstance || isCeatMaklerInstance) {
        return {
            root: {
                width: '100%',
                height: 40,
                backgroundColor: theme.palette.common.white,
                '& fieldset': {
                    borderRadius: 0,
                },
            },
            focused: {
                '& fieldset': {
                    borderColor: `${theme.palette.info.main}!important`,
                },
            },
            input: {
                height: 40,
                boxSizing: 'border-box',
                color: theme.palette.secondary.main,
                fontSize: '1.8rem',
                '&::placeholder': {
                    color: theme.palette.grey['700'],
                    fontSize: '1.8rem',
                },
                '&:-internal-autofill-selected': {
                    boxShadow: `inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px ${
                        theme.palette.type === THEME_MODES.LIGHT
                            ? theme.palette.primary.main
                            : LIGHT_HECTOR_THEME_COLORS.SECONDARY_MAIN_COLOR
                    }`,
                },
            },
        };
    }
    if (isHectorInstance) {
        return {
            root: {
                width: '100%',
                height: 40,
                backgroundColor: theme.palette.common.white,
                '& fieldset': {
                    borderRadius: 0,
                },
            },
            focused: {
                '& fieldset': {
                    borderColor: `${theme.palette.info.main}!important`,
                },
            },
            input: {
                height: 40,
                boxSizing: 'border-box',
                color: `${LIGHT_HECTOR_THEME_COLORS.SECONDARY_MAIN_COLOR}!important`,
                fontSize: '1.8rem',
                '&::placeholder': {
                    color: theme.palette.grey['700'],
                    fontSize: '1.8rem',
                },
            },
        };
    }
    return {
        root: {
            width: '100%',
            height: 40,
            backgroundColor: theme.palette.primary.main,
            borderRadius: 0,
        },
        focused: {
            '& fieldset': {
                borderColor: `${theme.palette.info.main}!important`,
            },
        },
        input: {
            height: 40,
            boxSizing: 'border-box',
            '&:-internal-autofill-selected': {
                boxShadow: `inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px ${theme.palette.primary.main}`,
            },
        },
    };
});
