import React, { ChangeEvent, ComponentType, ReactElement } from 'react';
import clsx from 'clsx';
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import {
    isHectorInstance,
    isSGIFFOXXKInstance,
    isCeatInstance,
    isCeatMaklerInstance,
} from 'src/environment';

import { useStyle, useFormControlLabelStyles } from './theme-radio-buttons-group-styles';

const CheckedRadioIcon =
    isHectorInstance || isSGIFFOXXKInstance || isCeatInstance || isCeatMaklerInstance
        ? FiberManualRecordIcon
        : RadioButtonCheckedIcon;

const RadioIcon = ({
    Icon,
    className,
}: {
    Icon: ComponentType;
    className: string;
}): ReactElement => {
    return (
        <div className={className}>
            <Icon />
        </div>
    );
};

export interface RadioButton {
    value: string;
    label: string;
}

interface ThemeRadioButtonsGroupProps {
    value: string;
    onChange: (e: ChangeEvent) => void;
    radioButtonsList: RadioButton[];
    row?: boolean;
    withoutDisplayingValue?: boolean;
    injectedIconClass?: string;
    injectedCheckedIconClass?: string;
    injectedFormControlLabelClass?: string;
    injectedFormControlClass?: string;
}

export const ThemeRadioButtonsGroup = (
    props: ThemeRadioButtonsGroupProps
): JSX.Element => {
    const classes = useStyle();
    const formControlLabelClasses = useFormControlLabelStyles();
    const {
        value,
        onChange,
        radioButtonsList,
        row,
        withoutDisplayingValue,
        injectedIconClass,
        injectedCheckedIconClass,
        injectedFormControlLabelClass,
        injectedFormControlClass,
    } = props;
    return (
        <FormControl component='fieldset' className={injectedFormControlClass}>
            <RadioGroup row={row} value={value} onChange={onChange}>
                {radioButtonsList.map((button: RadioButton) => (
                    <FormControlLabel
                        key={button.value}
                        value={button.value}
                        classes={formControlLabelClasses}
                        className={injectedFormControlLabelClass}
                        control={
                            <Radio
                                icon={
                                    <RadioIcon
                                        Icon={RadioButtonUncheckedIcon}
                                        className={clsx(classes.icon, injectedIconClass)}
                                    />
                                }
                                checkedIcon={
                                    <RadioIcon
                                        Icon={CheckedRadioIcon}
                                        className={clsx(
                                            classes.icon,
                                            injectedIconClass,
                                            classes.checkedIcon,
                                            injectedCheckedIconClass
                                        )}
                                    />
                                }
                            />
                        }
                        label={!withoutDisplayingValue && button.label}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};
