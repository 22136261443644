import { useState, useEffect } from 'react';
import * as signalR from '@microsoft/signalr';

import { baseUrl } from 'src/environment';

interface SocketConnectionProps {
    isAccessToEstablishConnection: boolean;
    url: string;
    accessToken: string;
}

export const useSocketConnection = ({
    isAccessToEstablishConnection,
    url,
    accessToken,
}: SocketConnectionProps): signalR.HubConnection => {
    const [connection, setConnection] = useState<signalR.HubConnection>(null);

    useEffect(() => {
        const cleanupConnection = async (): Promise<void> => {
            if (connection) {
                await connection.stop();
                setConnection(null);
            }
        };

        const establishConnection = async (): Promise<void> => {
            await cleanupConnection();

            if (isAccessToEstablishConnection && accessToken) {
                try {
                    const newConnection = new signalR.HubConnectionBuilder()
                        .withUrl(`${baseUrl}/${url}`, {
                            accessTokenFactory: () => accessToken,
                        })
                        .withAutomaticReconnect()
                        .build();
                    setConnection(newConnection);
                    await newConnection.start();
                } catch (err) {
                    Promise.reject(err);
                }
            }
        };

        establishConnection();

        return (): void => {
            cleanupConnection();
        };
    }, [isAccessToEstablishConnection, accessToken]);

    return connection;
};
