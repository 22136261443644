import React, { useState } from 'react';
import { DarkMode } from 'use-dark-mode';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
    Button,
    FormControl,
    FormControlLabel,
    OutlinedInput,
    Typography,
    Link,
} from '@material-ui/core';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { ThemeCircularProgress } from 'src/theming';
import {
    isCeatInstance,
    isHectorInstance,
    isAonMotorInstance,
    isAonDigitalInstance,
    isHectorDemoInstance,
    isCeatMaklerInstance,
} from 'src/environment';

import {
    useStyle,
    useFormControlLabelStyles,
    useFormControlStyles,
    useInputStyles,
} from '../styles';

import { forgotPasswordSchema } from './forgot-password-form-validation-schema';

type ForgotPasswordFormData = {
    email: string;
};

interface ForgotPasswordFormProps {
    darkMode: DarkMode;
}

export const ForgotPasswordForm = (props: ForgotPasswordFormProps): JSX.Element => {
    const { darkMode } = props;

    const httpClient = useHttpClient();

    const { enqueueSnackbar } = useSnackbar();

    const { t } = useTranslation(['user-management', 'common', 'errors']);

    const classes = useStyle({ isDarkMode: darkMode.value });
    const inputClasses = useInputStyles();
    const formControlClasses = useFormControlStyles();
    const formControlLabelClasses = useFormControlLabelStyles();

    const [isRequestLoading, setRequestLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { isValid, errors },
        reset,
    } = useForm<ForgotPasswordFormData>({
        resolver: yupResolver(forgotPasswordSchema),
        mode: 'onChange',
    });

    const onSubmit = async (vals: ForgotPasswordFormData): Promise<void> => {
        try {
            setRequestLoading(true);
            await httpClient.post('users/reset-password', { email: vals.email });
            setRequestLoading(false);
            enqueueSnackbar(
                t(`user-management:successResponses.requestForResetSuccessfully`, {
                    email: vals.email,
                }),
                {
                    variant: 'success',
                }
            );
            reset();
        } catch (err) {
            const errorMessage = err?.error?.response?.data?.errors?.email[0];
            setRequestLoading(false);
            enqueueSnackbar(
                errorMessage
                    ? t(
                          `user-management:errorResponses.${err.error.response.data.errors.email[0]}`
                      )
                    : t('errors:unknownError'),
                {
                    variant: 'error',
                }
            );
        }
    };

    return (
        <form
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit(onSubmit)}
            className={classes.formContainer}
        >
            <Typography variant='h3' className={classes.formTitle}>
                {t('forgotPassword')}
            </Typography>
            <Typography variant='body1' className={classes.formMessage}>
                {t('forgotPasswordMessage')}
            </Typography>
            {!isHectorDemoInstance &&
                !isHectorInstance &&
                !isAonDigitalInstance &&
                !isAonMotorInstance &&
                !isCeatInstance &&
                !isCeatMaklerInstance && <hr />}
            <FormControl
                error={Boolean(errors?.email)}
                fullWidth
                classes={formControlClasses}
                variant='outlined'
            >
                <FormControlLabel
                    classes={formControlLabelClasses}
                    control={
                        <OutlinedInput
                            type='email'
                            placeholder={t('placeholders.enterEmail')}
                            classes={inputClasses}
                            {...register('email')}
                        />
                    }
                    label={t('fields.email')}
                    labelPlacement='top'
                />
            </FormControl>
            <Button
                disableElevation
                disabled={!isValid || isRequestLoading}
                className={classes.submitButton}
                variant='contained'
                type='submit'
                startIcon={<ThemeCircularProgress isLoading={isRequestLoading} />}
            >
                {t('common:send')}
            </Button>
            <Typography variant='body2' className={classes.goToLoginLink}>
                {t('common:backTo')} <Link href='login'>{t('login')}</Link>
            </Typography>
        </form>
    );
};
