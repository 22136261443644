import { makeStyles, Theme } from '@material-ui/core';
import { THEME_MODES } from 'src/theming';
import {
    isAonDigitalInstance,
    isAonMotorInstance,
    isHectorInstance,
    isSGIFFOXXKInstance,
    isCeatInstance,
    isHectorDemoInstance,
    isCeatMaklerInstance,
} from 'src/environment';
import { getBackgroundGradient } from 'src/contracts/contracts-dashboard/contract-dashboard-styles/contract-dashboard-hector-styles';

export const useStyle = makeStyles((theme: Theme) => {
    const isLightMode = THEME_MODES.LIGHT === theme.palette.type;
    return {
        notificationsContainer: {
            flexDirection: 'column',
            height: '100%',
            overflow: 'auto',
        },
        header: {
            padding: () => {
                if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
                    return `${theme.spacing(5)}px 120px`;
                }
                if (
                    isHectorInstance ||
                    isSGIFFOXXKInstance ||
                    isCeatInstance ||
                    isCeatMaklerInstance
                ) {
                    return `${theme.spacing(5)}px 140px`;
                }
                return `${theme.spacing(5)}px ${theme.spacing(1)}rem`;
            },
            [theme.breakpoints.down(
                isHectorInstance ||
                    isSGIFFOXXKInstance ||
                    isAonDigitalInstance ||
                    isAonMotorInstance ||
                    isCeatInstance ||
                    isCeatMaklerInstance ||
                    isHectorDemoInstance
                    ? 'lg'
                    : 'md'
            )]: {
                padding: `${theme.spacing(2)}px ${theme.spacing(1)}px!important`,
            },
        },
        title: {
            display: 'flex',
            alignItems: 'center',
            height: 40,
            color: theme.palette.primary.contrastText,
        },
        content: {
            alignContent: 'flex-start',
            flexDirection: 'column',
            flexGrow: 1,
            marginBottom: theme.spacing(2),
            padding: () => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return `0 120px`;
                }
                if (
                    isHectorInstance ||
                    isSGIFFOXXKInstance ||
                    isCeatInstance ||
                    isCeatMaklerInstance
                ) {
                    return `0 140px`;
                }
                return `0 ${theme.spacing(10)}px`;
            },
            backgroundColor: () => {
                if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
                    return theme.palette.primary.main;
                }
                return theme.palette.primary.light;
            },
            [theme.breakpoints.down(
                isHectorInstance ||
                    isSGIFFOXXKInstance ||
                    isAonDigitalInstance ||
                    isAonMotorInstance ||
                    isCeatInstance ||
                    isCeatMaklerInstance ||
                    isHectorDemoInstance
                    ? 'lg'
                    : 'md'
            )]: {
                padding: `0 ${theme.spacing(1)}px!important`,
            },
        },
        columnTitlesContainer: {
            padding: `${theme.spacing(4)}px 0`,
            alignItems: 'center',
        },
        columnTitle: {
            fontSize: '1.8rem',
            color: theme.palette.primary.contrastText,
            [theme.breakpoints.down('md')]: {
                fontSize: '1.6rem',
            },
        },
        rowsContainer: {
            width: '100%',
            alignContent: 'flex-start',
            height: ({ rowsMaxHeight }: { rowsMaxHeight: number }) =>
                `${rowsMaxHeight}px`,
            overflow: 'auto',
        },
        toolBar: {
            height:
                isSGIFFOXXKInstance ||
                isHectorInstance ||
                isAonDigitalInstance ||
                isAonMotorInstance ||
                isCeatInstance ||
                isCeatMaklerInstance ||
                isHectorDemoInstance
                    ? 110
                    : 75,
            alignItems: 'center',
            padding: () => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return '0 120px';
                }
                if (
                    isHectorInstance ||
                    isSGIFFOXXKInstance ||
                    isCeatInstance ||
                    isCeatMaklerInstance
                ) {
                    return '0 140px';
                }
                return `0 ${theme.spacing(1)}rem`;
            },
            background: () => {
                if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
                    return !isLightMode
                        ? theme.palette.primary.main
                        : theme.palette.secondary.light;
                }
                if (isSGIFFOXXKInstance) {
                    return theme.palette.secondary.main;
                }
                if (isHectorInstance || isCeatInstance || isCeatMaklerInstance) {
                    return getBackgroundGradient(isLightMode, theme);
                }
                return THEME_MODES.DARK === theme.palette.type
                    ? theme.palette.primary.main
                    : theme.palette.primary.light;
            },
            borderBottom:
                THEME_MODES.DARK === theme.palette.type
                    ? `1px solid rgba(255, 255, 255, 0.2)`
                    : 'none',
            [theme.breakpoints.down(
                isHectorInstance ||
                    isSGIFFOXXKInstance ||
                    isAonDigitalInstance ||
                    isAonMotorInstance ||
                    isCeatInstance ||
                    isCeatMaklerInstance ||
                    isHectorDemoInstance
                    ? 'lg'
                    : 'md'
            )]: {
                height: 80,
                padding: `0 ${theme.spacing(1)}px!important`,
            },
        },
    };
});

export const useNotificationRowStyle = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        row: {
            width: '100%',
            marginBottom: theme.spacing(2),
            marginTop: `0!important`,
            alignItems: 'flex-start',
        },
        trigger: {
            marginTop: theme.spacing(4),
            fontSize: '1.4rem',
            color: theme.palette.primary.contrastText,
        },
        cell: {
            alignItems: 'center',
            minHeight: 70,
        },
        otherButton: {
            height: 30,
            padding: theme.spacing(2),
            backgroundColor: () => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return theme.palette.info.main;
                }
                return theme.palette.info.light;
            },
            color: () => {
                if (
                    isSGIFFOXXKInstance ||
                    isAonMotorInstance ||
                    isAonDigitalInstance ||
                    isHectorDemoInstance
                ) {
                    return theme.palette.info.contrastText;
                }
                return theme.palette.info.main;
            },
            fontSize: '1.2rem',
            transition: '0.2s',
            '& .MuiSvgIcon-root': {
                fontSize: '1.4rem',
            },
            '&:hover': {
                opacity: 0.8,
                backgroundColor: () => {
                    if (
                        isAonMotorInstance ||
                        isAonDigitalInstance ||
                        isHectorDemoInstance
                    ) {
                        return theme.palette.info.main;
                    }
                    return theme.palette.info.light;
                },
            },
        },
        iconButton: {
            width: 30,
            height: 30,
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(4),
            borderRadius: 4,
        },
        iconAddButton: {
            backgroundColor: theme.palette.info.light,
            color: () => {
                if (
                    isSGIFFOXXKInstance ||
                    isAonMotorInstance ||
                    isAonDigitalInstance ||
                    isHectorDemoInstance
                ) {
                    return theme.palette.info.contrastText;
                }
                return theme.palette.info.main;
            },
            transition: '0.2s',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: theme.palette.info.light,
            },
        },
        iconDeleteButton: {
            marginLeft: theme.spacing(2),
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.main,
        },
        showMoreLink: {
            marginRight: `auto`,
            marginTop: theme.spacing(1),
            fontWeight: theme.typography.fontWeightMedium,
        },
        emailsContainer: {
            marginLeft: `0!important`,
            marginRight: `0!important`,
        },
        icon: {
            display: 'flex',
            alignItems: 'center',
            width: 18,
            height: 18,
            color: () => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return isDarkMode
                        ? theme.palette.info.contrastText
                        : theme.palette.info.main;
                }
                return isDarkMode
                    ? theme.palette.secondary.contrastText
                    : theme.palette.secondary.main;
            },
            opacity: 0.6,
        },
        checkedIcon: {
            width: 18,
            height: 18,
            color: () => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return isDarkMode
                        ? theme.palette.info.contrastText
                        : theme.palette.info.main;
                }
                return theme.palette.info.main;
            },
            opacity: 1,
        },
        checkboxCell: {
            paddingTop: `${theme.spacing(6)}px!important`,
        },
    };
});

export const useFormControlStyles = makeStyles(() => {
    return {
        root: {
            width: '100%',
            '& label': {
                fontSize: '2.4rem',
            },
        },
    };
});
export const useFormControlLabelStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            alignItems: 'flex-start',
            margin: 0,
            '& .MuiInputBase-root': {
                width: '100%',
            },
        },
        label: {
            fontSize: '1.4rem',
            opacity: 0.6,
            color: theme.palette.primary.contrastText,
        },
        disabled: {
            '& .MuiFormControlLabel-label': {
                color: theme.palette.primary.contrastText,
            },
        },
    };
});

export const useFormControlCheckboxLabelStyle = makeStyles((theme: Theme) => {
    return {
        root: {
            alignItems: 'center',
        },
        label: {
            fontSize: '1.4rem',
            color: theme.palette.primary.contrastText,
        },
    };
});

export const useInputStyles = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        root: {
            width: '100%',
            height: 40,
            backgroundColor: () => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return isDarkMode ? 'rgba(0, 0, 0, 0)' : theme.palette.primary.main;
                }
                return theme.palette.primary.light;
            },
            borderRadius: 4,
            fontSize: '14px',
        },
        focused: {
            '& fieldSet': {
                borderColor: `${theme.palette.info.main}!important`,
            },
        },
        input: {
            '&:-internal-autofill-selected': {
                boxShadow: `inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px ${theme.palette.primary.main}`,
            },
        },
    };
});
