import React, { useMemo, useState, ChangeEvent, useEffect } from 'react';
import useDarkMode from 'use-dark-mode';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    Button,
    FormControl,
    Grid,
    Box,
    Typography,
    FormControlLabel,
    IconButton,
    useTheme,
    useMediaQuery,
} from '@material-ui/core';

import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import FlagIcon from '@material-ui/icons/Flag';
import PrintIcon from '@material-ui/icons/Print';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';

import { useDashboardSettings } from 'src/dashboard/variants/dashboard-use-dashboard-settings';
import { useAuth } from 'src/user-management/context-auth';
import { useDashboardContext } from 'src/shared/contexts';
import { useInIframe } from 'src/shared/hooks';
import {
    isAonMotorInstance,
    isHectorInstance,
    isSGIFFOXXKInstance,
    isAonDigitalInstance,
    isCeatInstance,
    isHectorDemoInstance,
    isCeatMaklerInstance,
} from 'src/environment';

import {
    useFormControlYearStyles,
    useFormControlLabelStyles,
    useStyle,
    useSelectClasses,
    useMenuClasses,
} from './contract-dashboard-styles';

import {
    ThemeLoader,
    ThemeRadioButtonsGroup,
    ThemeSelectInput,
    ThemeSearchField,
} from 'src/theming';
import { APP_ROUTES } from 'src/routing';

import { useGlobalFilter } from 'src/shared/contexts';

import { Can, AbilityActions, AbilitySubjects } from 'src/roleAccesses';

import { ContractsNavigationPanel } from '../contracts-navigation-panel/contracts-navigation-panel';
import { DivisionsSection } from '../division-section/division-section';
import { ContractsDashboardPrintModalWindow } from './contracts-dashboard-print-modal-window';
import { ContractDashboardEditActions } from './contracts-dashboard-edit-actions';

import {
    generateContractTypeSearchOptions,
    SearchOptions,
} from './generateContractTypeSearchOptions';

import {
    useContractsDetailsSearch,
    useDamagesDetailsSearch,
} from 'src/contracts/contracts-hooks';

import { ContractsDashboardDamageLinkQuickAction } from './contracts-dashboard-damage-link-quick-action';
import { Divisions } from 'src/shared/constants';

import { DamageStatuses } from '../damages-list/damage-list-configs';

import { ContractsDashboardVariantSelection } from './contracts-dashboard-variant-selection';
import { generateYears } from './contract-dashboard-helpers';

const CurrentPrintIcon =
    isHectorInstance ||
    isSGIFFOXXKInstance ||
    isAonMotorInstance ||
    isHectorDemoInstance ||
    isAonDigitalInstance ||
    isCeatInstance ||
    isCeatMaklerInstance
        ? PrintOutlinedIcon
        : PrintIcon;

type SearchData = {
    optionToSearch: string;
    search: string;
    years: { name: string }[];
};

export const ContractsDashboard = (): JSX.Element => {
    const darkMode = useDarkMode();
    const history = useHistory();
    const theme = useTheme();
    const matchesUpLargeSize = useMediaQuery(theme.breakpoints.up('lg'));

    const { t } = useTranslation(['common', 'contract-types']);

    const isInIFrame = useInIframe();
    const { division, changeDivision } = useGlobalFilter();
    const { settings, handleChangeShown, moveCard } = useDashboardSettings({
        division,
    });
    const { userData } = useAuth();
    const {
        selectedYears,
        handleChangeYear,
        updateYearsForRequest,
        dashboardData,
        yearsForRequest,
    } = useDashboardContext();

    const [isPrintModalWindowOpen, setPrintModalWindowOpen] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [availableToPrint, setAvailableToPrint] = useState<boolean>(false);

    const classes = useStyle({ editMode, isDarkMode: darkMode.value });
    const menuClasses = useMenuClasses();
    const formControlLabelClasses = useFormControlLabelStyles();
    const yearFormControlClasses = useFormControlYearStyles();
    const selectClasses = useSelectClasses();

    const { register, handleSubmit, setValue, watch, reset, formState } =
        useForm<SearchData>({
            mode: 'onChange',
            defaultValues: {
                optionToSearch: SearchOptions.CONTRACT,
            },
        });

    const [optionToSearch, search] = watch(['optionToSearch', 'search']);

    const searchOptions = useMemo(() => generateContractTypeSearchOptions(t), [t]);

    const contractsSearchResults = useContractsDetailsSearch(
        optionToSearch === SearchOptions.CONTRACT && formState.isSubmitted && !isInIFrame,
        search,
        null,
        null,
        null,
        null
    );
    const damagesSearchResults = useDamagesDetailsSearch(
        optionToSearch === SearchOptions.DAMAGE && formState.isSubmitted && !isInIFrame,
        search
    );

    useEffect(() => {
        if (
            Array.isArray(contractsSearchResults.searchResults?.result) &&
            contractsSearchResults.searchResults?.result.length !== 0
        ) {
            if (contractsSearchResults.searchResults.result.length === 1) {
                history.push(
                    `${APP_ROUTES.CONTRACTS}/${contractsSearchResults.searchResults.result[0].amsIdNr}`
                );
            } else if (contractsSearchResults.searchResults?.result.length > 1) {
                history.push(APP_ROUTES.GLOBAL_SEARCH, {
                    type: optionToSearch,
                    globalSearchTerm: search,
                });
            }
        } else if (
            Array.isArray(damagesSearchResults.searchResults?.result) &&
            damagesSearchResults.searchResults?.result?.length !== 0
        ) {
            if (damagesSearchResults.searchResults?.result?.length === 1) {
                history.push(
                    `${APP_ROUTES.DAMAGES}/${damagesSearchResults.searchResults.result[0].amsIdNr}`
                );
            } else if (damagesSearchResults.searchResults?.result?.length > 1) {
                history.push(APP_ROUTES.GLOBAL_SEARCH, {
                    type: optionToSearch,
                    globalSearchTerm: search,
                });
            }
        } else {
            reset({ optionToSearch: optionToSearch });
        }
    }, [contractsSearchResults.searchResults, damagesSearchResults.searchResults]);

    const handleChangeSearchOption = (event: ChangeEvent<HTMLInputElement>): void => {
        setValue('optionToSearch', event.target.value);
    };

    const onSearchSubmit = (): void => undefined;

    const handleGoToDamageReportForm = (): void => {
        history.push(APP_ROUTES.DAMAGE_REPORT);
    };

    const handleGoTo = (path: APP_ROUTES, state?): void => {
        history.push({ pathname: path, state });
    };

    const handleOpenPrintModalWindow = (): void => {
        setPrintModalWindowOpen(true);
    };

    const handleClosePrintModalWnidow = (): void => {
        setPrintModalWindowOpen(false);
    };

    const [loading, setLoading] = useState<boolean>(false);

    const mappedYears = useMemo(
        () => selectedYears.map((year) => String(year)),
        [selectedYears]
    );

    return (
        <Grid container wrap='nowrap' className={classes.dashboardContainer}>
            <Grid
                container
                item
                component='form'
                className={classes.toolBar}
                onSubmit={handleSubmit(onSearchSubmit)}
            >
                <ContractsNavigationPanel activeMenu='dashboard' />
                <div style={{ flexGrow: 1 }} />
                <ThemeRadioButtonsGroup
                    row
                    value={optionToSearch}
                    onChange={handleChangeSearchOption}
                    radioButtonsList={searchOptions}
                    injectedFormControlLabelClass={classes.radioButtonLabel}
                    injectedFormControlClass={classes.radioFormControl}
                />
                {(isHectorInstance ||
                    isSGIFFOXXKInstance ||
                    isAonMotorInstance ||
                    isAonDigitalInstance ||
                    isHectorDemoInstance) &&
                    matchesUpLargeSize && <div style={{ flexGrow: 1 }} />}
                <div className={classes.searchFieldContainer}>
                    <ThemeSearchField
                        disabled={
                            contractsSearchResults?.searchLoading ||
                            damagesSearchResults?.searchLoading
                        }
                        isLoading={
                            contractsSearchResults?.searchLoading ||
                            damagesSearchResults?.searchLoading
                        }
                        {...register('search')}
                    />
                </div>
            </Grid>
            <Grid container className={classes.dashboardSettingsContainer}>
                <Grid item sm={10}>
                    {Array.isArray(userData?.divisions) &&
                    userData?.divisions?.length > 1 ? (
                        <DivisionsSection
                            activeDivision={division}
                            editMode={editMode}
                            setEditMode={setEditMode}
                            handleOnClickCard={changeDivision}
                        />
                    ) : (
                        <ContractDashboardEditActions setEditMode={setEditMode} />
                    )}
                </Grid>
                <Grid item container sm={2} justify='flex-end' wrap='nowrap'>
                    <Grid item container justify='flex-end' sm={2}>
                        <IconButton
                            id='export-dashboard-button'
                            disabled={!dashboardData || !settings}
                            className={classes.printButton}
                            onClick={handleOpenPrintModalWindow}
                        >
                            <CurrentPrintIcon />
                        </IconButton>
                        <ContractsDashboardPrintModalWindow
                            isOpen={isPrintModalWindowOpen}
                            onClose={handleClosePrintModalWnidow}
                            years={mappedYears}
                            dashboardData={dashboardData}
                            settings={settings}
                        />
                    </Grid>
                    <Grid item sm={10} style={{ paddingLeft: 20, maxWidth: 250 }}>
                        <FormControl
                            fullWidth
                            variant='outlined'
                            classes={yearFormControlClasses}
                        >
                            <FormControlLabel
                                label={t('year')}
                                labelPlacement='start'
                                classes={formControlLabelClasses}
                                control={
                                    <ThemeSelectInput
                                        editMode
                                        multiple
                                        name='dashboard'
                                        value={mappedYears}
                                        items={generateYears()}
                                        onChange={handleChangeYear}
                                        callbackAfterClose={updateYearsForRequest}
                                        injectedMenuClasses={menuClasses}
                                        injectedSelectClasses={selectClasses}
                                    />
                                }
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container className={classes.contractsDashboardContainer}>
                {!settings && <ThemeLoader />}
                {settings && (
                    <ContractsDashboardVariantSelection
                        division={division}
                        key={division}
                        dashboardData={dashboardData}
                        years={yearsForRequest}
                        settings={settings}
                        handleChangeShown={handleChangeShown}
                        moveCard={moveCard}
                    />
                )}
            </Grid>
            <Grid item className={classes.quickActionsContainer}>
                <Box mb={matchesUpLargeSize ? 4 : 2}>
                    <Typography variant='h6'>
                        {t('contract-types:quickActions')}
                    </Typography>
                </Box>
                {loading && <ThemeLoader />}
                {division === Divisions.KFZW &&
                    userData?.divisions?.find(
                        (item) => item.value === Divisions.KFZW
                    ) && (
                        <Can
                            passThrough
                            I={AbilityActions.use}
                            a={AbilitySubjects.damageLink}
                        >
                            {(allowed: boolean) => {
                                return (
                                    allowed && (
                                        <ContractsDashboardDamageLinkQuickAction
                                            editMode={editMode}
                                            division={division}
                                            t={t}
                                            setLoading={setLoading}
                                        />
                                    )
                                );
                            }}
                        </Can>
                    )}
                {(division === Divisions.KFZW ||
                    division === Divisions.BHV ||
                    division === Divisions.PHV ||
                    division === Divisions.GBW ||
                    division === Divisions.TRANSP ||
                    division === Divisions.HR ||
                    division === Divisions.GESCH) &&
                    !loading && (
                        <Can
                            passThrough
                            I={AbilityActions.see}
                            a={AbilitySubjects.damageReport}
                        >
                            {(allowed: boolean) =>
                                allowed && (
                                    <Button
                                        disabled={editMode}
                                        disableElevation
                                        variant='contained'
                                        startIcon={<FlagIcon />}
                                        className={classes.quickActionsButton}
                                        onClick={handleGoToDamageReportForm}
                                    >
                                        {t('contract-types:reportDamage')}
                                    </Button>
                                )
                            }
                        </Can>
                    )}
                {!loading && (
                    <Can passThrough I={AbilityActions.see} a={AbilitySubjects.damages}>
                        {(allowed: boolean) =>
                            allowed && (
                                <Button
                                    disabled={editMode}
                                    disableElevation
                                    variant='contained'
                                    startIcon={<OpenInBrowserIcon />}
                                    className={classes.quickActionsButton}
                                    onClick={() =>
                                        handleGoTo(APP_ROUTES.DAMAGES, {
                                            status: DamageStatuses.OPEN,
                                        })
                                    }
                                >
                                    {t('contract-types:openDamage')}
                                </Button>
                            )
                        }
                    </Can>
                )}
            </Grid>
        </Grid>
    );
};
