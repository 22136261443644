import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { baseUrl, isDevelopment } from 'src/environment';

function configureAxiosGlobalSettings(): void {
    if (isDevelopment) axios.defaults.baseURL = baseUrl;
}

export function initializeAxios(): AxiosInstance {
    configureAxiosGlobalSettings();

    const configuration: AxiosRequestConfig = {};

    configuration.baseURL = baseUrl;

    return axios.create(configuration);
}
