import { makeStyles, Theme } from '@material-ui/core/styles';
import {
    isHectorInstance,
    isSGIFFOXXKInstance,
    isAonDigitalInstance,
    isAonMotorInstance,
    isCeatInstance,
    isHectorDemoInstance,
    isCeatMaklerInstance,
} from 'src/environment';
import { THEME_MODES } from '..';

export const useStyle = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
        return {
            container: {
                display: 'flex',
                overflowX: 'hidden',
                flexDirection: 'column',
                height: '100vh',
                padding: 0,
                backgroundColor: !isDarkMode
                    ? theme.palette.primary.light
                    : theme.palette.primary.dark,
            },
            header: {
                width: '100%',
                backgroundColor: isDarkMode
                    ? theme.palette.primary.dark
                    : theme.palette.primary.light,
            },
            main: {
                flexGrow: 1,
                backgroundColor: !isDarkMode
                    ? theme.palette.primary.light
                    : theme.palette.primary.dark,
            },
            footer: {
                width: '100%',
                minHeight: 60,
                maxHeight: 60,
                backgroundColor: isDarkMode
                    ? theme.palette.primary.dark
                    : theme.palette.primary.light,
                borderTop: `1px solid ${theme.palette.secondary.main}`,
                [theme.breakpoints.down('md')]: {
                    minHeight: 30,
                    maxHeight: 30,
                },
            },
        };
    }
    if (isSGIFFOXXKInstance) {
        return {
            container: {
                display: 'flex',
                overflowX: 'hidden',
                flexDirection: 'column',
                height: '100vh',
                padding: 0,
                backgroundColor: theme.palette.primary.light,
            },
            header: {
                width: '100%',
                backgroundColor: theme.palette.primary.light,
            },
            main: {
                flexGrow: 1,
                backgroundColor: theme.palette.primary.light,
            },
            footer: {
                width: '100%',
                minHeight: 60,
                maxHeight: 60,
                backgroundColor: theme.palette.primary.main,
                [theme.breakpoints.down('md')]: {
                    minHeight: 30,
                    maxHeight: 30,
                },
            },
        };
    }
    if (isHectorInstance || isCeatInstance || isCeatMaklerInstance) {
        return {
            container: {
                display: 'flex',
                overflowX: 'hidden',
                flexDirection: 'column',
                height: '100vh',
                padding: 0,
                backgroundColor: theme.palette.primary.main,
            },
            header: {
                width: '100%',
                backgroundColor: theme.palette.primary.light,
            },
            main: {
                flexGrow: 1,
                backgroundColor:
                    theme.palette.type === THEME_MODES.LIGHT
                        ? theme.palette.primary.main
                        : theme.palette.primary.light,
            },
            footer: {
                width: '100%',
                minHeight: 60,
                maxHeight: 60,
                backgroundColor: theme.palette.primary.dark,
                '& a,span': {
                    color:
                        THEME_MODES.DARK === theme.palette.type
                            ? theme.palette.secondary.contrastText
                            : theme.palette.secondary.main,
                },
                [theme.breakpoints.down('md')]: {
                    minHeight: 30,
                    maxHeight: 30,
                },
            },
        };
    }
    return {
        container: {
            display: 'flex',
            overflowX: 'hidden',
            flexDirection: 'column',
            height: '100vh',
            padding: 0,
            backgroundColor: theme.palette.primary.main,
        },
        header: {
            position: 'sticky',
            top: 0,
            width: '100%',
            backgroundColor: theme.palette.secondary.main,
            zIndex: 1000,
        },
        main: {
            flexGrow: 1,
            backgroundColor: theme.palette.primary.main,
        },
        footer: {
            width: '100%',
            minHeight: 28,
            maxHeight: 28,
            backgroundColor: theme.palette.secondary.main,
        },
    };
});
