import React from 'react';

import { DarkMode } from 'use-dark-mode';
import {
    ForgotPasswordForm,
    UserLayout,
    UserAONLayout,
    UserHectorLayout,
    UserSgiffoxxLayout,
    UserAonMotorLayout,
    UserCeatLayout,
    UserCeatMaklerLayout,
} from 'src/user-management';

import { AonMotorThemeProvider } from 'src/shared/hocs/aon-motor-theme-provider';
import { AonThemeProvider } from 'src/shared/hocs/aon-theme-provider';
import { HectorThemeProvider } from 'src/shared/hocs/hector-theme-provider';
import { CeatThemeProvider } from 'src/shared/hocs/ceat-theme-provider';
import { appInstance } from 'src/environment';
import { AppInstances } from 'src/shared/constants';

interface Props {
    darkMode: DarkMode;
}

export const ForgotPasswordPage = ({ darkMode }: Props): JSX.Element => {
    switch (appInstance) {
        case AppInstances.AON:
        case AppInstances.AON_KOMPOSIT: {
            return (
                <AonThemeProvider>
                    <UserAONLayout darkMode={darkMode}>
                        <ForgotPasswordForm darkMode={darkMode} />
                    </UserAONLayout>
                </AonThemeProvider>
            );
        }
        case AppInstances.AON_MOTOR:
        case AppInstances.AON_DIGITAL:
        case AppInstances.HECTOR_DEMO: {
            return (
                <AonMotorThemeProvider>
                    <UserAonMotorLayout darkMode={darkMode}>
                        <ForgotPasswordForm darkMode={darkMode} />
                    </UserAonMotorLayout>
                </AonMotorThemeProvider>
            );
        }
        case AppInstances.SGIFFOXXK: {
            return (
                <UserSgiffoxxLayout darkMode={darkMode}>
                    <ForgotPasswordForm darkMode={darkMode} />
                </UserSgiffoxxLayout>
            );
        }
        case AppInstances.HECTOR: {
            return (
                <HectorThemeProvider>
                    <UserHectorLayout darkMode={darkMode}>
                        <ForgotPasswordForm darkMode={darkMode} />
                    </UserHectorLayout>
                </HectorThemeProvider>
            );
        }
        case AppInstances.CEAT: {
            return (
                <CeatThemeProvider>
                    <UserCeatLayout darkMode={darkMode}>
                        <ForgotPasswordForm darkMode={darkMode} />
                    </UserCeatLayout>
                </CeatThemeProvider>
            );
        }
        case AppInstances.CEAT_MAKLER: {
            return (
                <CeatThemeProvider>
                    <UserCeatMaklerLayout darkMode={darkMode}>
                        <ForgotPasswordForm darkMode={darkMode} />
                    </UserCeatMaklerLayout>
                </CeatThemeProvider>
            );
        }
        default: {
            return (
                <UserLayout darkMode={darkMode}>
                    <ForgotPasswordForm darkMode={darkMode} />
                </UserLayout>
            );
        }
    }
};
