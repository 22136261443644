import React, {
    useState,
    useContext,
    createContext,
    Dispatch,
    SetStateAction,
} from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { useDashboardData, UseDashboardData } from './use-dashboard-data';

type DashboardContextType = {
    isCanceled: boolean;
    editMode: boolean;
    isOpen: boolean;
    isUpdatingLoading: boolean;
    handleOpen: () => void;
    handleClose: () => void;
    handleUpdate: () => void;
    handleEditMode: () => void;
    handleCancelEditMode: () => void;
    handleCloseEditMode: () => void;
    setCanceled: (value: boolean) => void;
    handleSaveDivisionSettings: () => void;
    setNeedToSaveSettings: (value: boolean) => void;
    needToSaveSettings: boolean;
    isLoadingUpdateDashboardSettings: boolean;
    setLoadingUpdateDashboardSettings: Dispatch<SetStateAction<boolean>>;
};

export const DashboardContext =
    createContext<DashboardContextType & UseDashboardData>(null);

export const useDashboardContext = (): DashboardContextType & UseDashboardData =>
    useContext<DashboardContextType & UseDashboardData>(DashboardContext);

export const DashboardContextProvider = ({ children }): JSX.Element => {
    const { t } = useTranslation(['common', 'errors']);

    const [isOpen, setOpen] = useState<boolean>(false);
    const [isUpdatingLoading, setUpdatingLoading] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [isCanceled, setCanceled] = useState<boolean>(false);
    const [needToSaveSettings, setNeedToSaveSettings] = useState<boolean>(false);
    const [isLoadingUpdateDashboardSettings, setLoadingUpdateDashboardSettings] =
        useState<boolean>(false);

    const {
        selectedYears,
        handleChangeYear,
        isGetDashboardDataLoading,
        updateYearsForRequest,
        dashboardData,
        yearsForRequest,
    } = useDashboardData();

    const httpClient = useHttpClient();
    const { enqueueSnackbar } = useSnackbar();

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const makeRequestToUpdateDashboardData = async (): Promise<void> => {
        setUpdatingLoading(true);
        return await httpClient.post('dashboard/recalculate-data');
    };

    const handleUpdate = async (): Promise<void> => {
        try {
            await makeRequestToUpdateDashboardData();
            enqueueSnackbar(t('updateDashboardSuccessMessage'), { variant: 'success' });
        } catch (err) {
            enqueueSnackbar(t('errors:unknownError'), { variant: 'error' });
        } finally {
            setUpdatingLoading(false);
            handleClose();
        }
    };

    const handleEditMode = (): void => {
        setEditMode(true);
    };

    const handleCancelEditMode = (): void => {
        setCanceled(true);
    };

    const handleCloseEditMode = (): void => {
        setEditMode(false);
    };

    const handleSaveDivisionSettings = (): void => {
        setNeedToSaveSettings(true);
    };

    const contextProps: DashboardContextType & UseDashboardData = {
        isCanceled,
        setCanceled,
        editMode,
        isOpen,
        isUpdatingLoading,
        handleOpen,
        handleClose,
        handleUpdate,
        handleEditMode,
        handleCancelEditMode,
        handleSaveDivisionSettings,
        handleCloseEditMode,
        setNeedToSaveSettings,
        needToSaveSettings,
        isLoadingUpdateDashboardSettings,
        setLoadingUpdateDashboardSettings,
        selectedYears,
        handleChangeYear,
        isGetDashboardDataLoading,
        updateYearsForRequest,
        dashboardData,
        yearsForRequest,
    };

    return (
        <DashboardContext.Provider value={contextProps}>
            {children}
        </DashboardContext.Provider>
    );
};
