import { makeStyles, Theme } from '@material-ui/core/styles';
import { THEME_MODES } from 'src/theming';

import {
    isAonDigitalInstance,
    isAonMotorInstance,
    isCeatInstance,
    isHectorInstance,
    isSGIFFOXXKInstance,
    isHectorDemoInstance,
    isCeatMaklerInstance,
} from 'src/environment';

export const useStyle = makeStyles((theme: Theme) => {
    const isLightMode = THEME_MODES.LIGHT === theme.palette.type;
    return {
        faqListContainer: {
            alignItems: 'flex-start',
            alignContent: 'flex-start',
            flexDirection: 'column',
            height: '100%',
            padding: () => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return `0 120px ${theme.spacing(1)}rem 120px`;
                }
                if (
                    isSGIFFOXXKInstance ||
                    isHectorInstance ||
                    isCeatInstance ||
                    isCeatMaklerInstance
                ) {
                    return `0 140px ${theme.spacing(1)}rem 140px`;
                }
                return `0 ${theme.spacing(1)}rem ${theme.spacing(1)}rem ${theme.spacing(
                    1
                )}rem`;
            },
            [theme.breakpoints.down(isHectorInstance ? 'lg' : 'md')]: {
                padding: () => {
                    if (
                        isSGIFFOXXKInstance ||
                        isHectorInstance ||
                        isHectorInstance ||
                        isAonMotorInstance ||
                        isAonDigitalInstance ||
                        isHectorDemoInstance
                    ) {
                        return `0 ${theme.spacing(1)}px ${theme.spacing(
                            1
                        )}rem ${theme.spacing(1)}px`;
                    }
                    return `0 ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
                        1
                    )}px`;
                },
            },
        },
        faqListHeader: {
            justifyContent: 'space-between',
            padding: `${theme.spacing(6)}px 0`,
            '& h5': {
                display: 'flex',
                alignItems: 'center',
                color: theme.palette.primary.contrastText,
            },
            [theme.breakpoints.down('md')]: {
                padding: `${theme.spacing(3)}px 0`,
            },
        },
        faqQuestionsContainer: {
            flexGrow: 1,
            padding: theme.spacing(6),
            backgroundColor: () => {
                if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
                    return isLightMode
                        ? theme.palette.primary.light
                        : theme.palette.primary.dark;
                }
                return theme.palette.primary.light;
            },
            [theme.breakpoints.down(
                isHectorInstance || isCeatInstance || isCeatMaklerInstance ? 'lg' : 'md'
            )]: {
                padding: () => {
                    if (
                        isSGIFFOXXKInstance ||
                        isHectorInstance ||
                        isCeatInstance ||
                        isCeatMaklerInstance ||
                        isAonMotorInstance ||
                        isAonDigitalInstance ||
                        isHectorDemoInstance
                    ) {
                        return `0 ${theme.spacing(1)}px ${theme.spacing(
                            1
                        )}rem ${theme.spacing(1)}px`;
                    }
                    return `0 ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
                        1
                    )}px`;
                },
            },
        },
        modalActionsContainer: {
            gap: theme.spacing(3),
            justifyContent: 'flex-end',
        },
        modalButton: {
            color: theme.palette.primary.contrastText,
        },
        saveButton: {
            color: theme.palette.secondary.contrastText,
            backgroundColor: () => {
                if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
                    return !isLightMode
                        ? theme.palette.info.main
                        : theme.palette.secondary.dark;
                }
                return !isLightMode
                    ? theme.palette.info.main
                    : theme.palette.secondary.main;
            },
            transition: '0.2s',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: () => {
                    if (
                        isAonDigitalInstance ||
                        isAonMotorInstance ||
                        isHectorDemoInstance
                    ) {
                        return !isLightMode
                            ? theme.palette.info.main
                            : theme.palette.secondary.dark;
                    }
                    return !isLightMode
                        ? theme.palette.info.main
                        : theme.palette.secondary.main;
                },
            },
        },
        cancelButton: {
            borderColor:
                theme.palette.type === THEME_MODES.DARK
                    ? theme.palette.info.main
                    : theme.palette.secondary.main,
        },
    };
});
