import React, {
    createContext,
    useContext,
    useState,
    SetStateAction,
    Dispatch,
    useEffect,
} from 'react';
import { UseFormSetValue, UseFormReset, FormState } from 'react-hook-form';

import { useAuth } from 'src/user-management/context-auth';
import { useAbilityCtx, AbilityActions, AbilitySubjects } from 'src/roleAccesses';
import { useSocketConnection } from 'src/shared/hooks';
import { MessageDTO } from './notification-center-message-dto';

import {
    useNotificationCenterNewMessage,
    NotificationCenterNewMessageFormData,
} from './notification-center-use-new-message';
import { useNotificationCenterMessages } from './notification-center-use-messages';
import { NotificationCenterSections } from './notification-center-enums';
import { defaultValues } from './notification-center-use-new-message';

type NotificationCenterContextType = {
    getMessages: (notificationSection: string, userId: string) => Promise<void>;
    updateNumberOfUnreadMessages: () => Promise<void>;
    messages: MessageDTO[];
    amountOfUnreadMessages: number;
    notificationSection: NotificationCenterSections;
    setNotificationsSection: Dispatch<SetStateAction<NotificationCenterSections>>;
    values: NotificationCenterNewMessageFormData;
    setValue: UseFormSetValue<NotificationCenterNewMessageFormData>;
    userReplyId: string;
    setUserReplyId: (id: string) => void;
    userReplyName: string;
    setUserReplyName: (id: string) => void;
    reset: UseFormReset<NotificationCenterNewMessageFormData>;
    formState: FormState<NotificationCenterNewMessageFormData>;
    isReceiveLoading: boolean;
};

export const NotificationCenterContext =
    createContext<NotificationCenterContextType>(null);

export const useNotificationCenterContext = (): NotificationCenterContextType =>
    useContext<NotificationCenterContextType>(NotificationCenterContext);

export const NotificationCenterProvider = ({ children }): JSX.Element => {
    const ability = useAbilityCtx();
    const [notificationSection, setNotificationsSection] =
        useState<NotificationCenterSections>(NotificationCenterSections.UNREAD_MESSAGES);
    const [userReplyId, setUserReplyId] = useState<string>('');
    const [userReplyName, setUserReplyName] = useState<string>('');
    const [amountOfUnreadMessages, setAmountOfUnreadMessages] = useState<number>(0);

    const { authorized, userData } = useAuth();

    const {
        messages,
        isLoading: isReceiveLoading,
        getMessages,
        getNumberOfUnreadMessages,
    } = useNotificationCenterMessages(notificationSection);

    const { values, setValue, reset, formState } =
        useNotificationCenterNewMessage(userReplyId);

    const isAccessToEstablishConnection =
        authorized &&
        ability.can(AbilityActions.establish, AbilitySubjects.socketConnection);

    const connection = useSocketConnection({
        isAccessToEstablishConnection,
        url: 'notificationMessage',
        accessToken: localStorage.getItem('accessToken'),
    });

    const updateNumberOfUnreadMessages = async (): Promise<void> => {
        const amount = await getNumberOfUnreadMessages(userData?.userInfo?.id);
        setAmountOfUnreadMessages(amount);
    };

    const subscribeToNotifications = async (data: number): Promise<void> => {
        setAmountOfUnreadMessages(data);
    };

    useEffect(() => {
        if (connection) {
            connection.on('SendMessage', subscribeToNotifications);
        }
    }, [connection, userData, notificationSection]);

    const contextProps = {
        messages,
        notificationSection,
        setNotificationsSection,
        amountOfUnreadMessages,
        values,
        setValue,
        userReplyId,
        userReplyName,
        setUserReplyId,
        setUserReplyName,
        reset,
        formState,
        isReceiveLoading,
        getMessages,
        updateNumberOfUnreadMessages,
    };

    useEffect(() => {
        if (!authorized) {
            reset(defaultValues);
            setNotificationsSection(NotificationCenterSections.UNREAD_MESSAGES);
            if (connection) {
                connection.off('SendMessage', subscribeToNotifications);
            }
        }
    }, [authorized]);

    return (
        <NotificationCenterContext.Provider value={contextProps}>
            {children}
        </NotificationCenterContext.Provider>
    );
};
