import React, { useMemo } from 'react';
import { Grid, List, ListItem, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { CustomerDetailsViewDTO } from './customer-details-view-dto';
import { ContractDetailsListItem } from '../../contracts/contracts-details-view/contract-details-list-item';
import { ThemeNestedMenu, ThemeAddress } from 'src/theming';
import { useTranslation } from 'react-i18next';
import { useCustomerDetailsViewStyle, useStyle } from './customer-details-view-styles';
import { generateCustomerDetailsViewAdditionalMenu } from './customer-details-view-configs';

interface CustomerDetailsViewDataProps {
    data: CustomerDetailsViewDTO;
    openChangeNotification: () => void;
}

export const CustomerDetailsViewData = ({
    data,
    openChangeNotification,
}: CustomerDetailsViewDataProps): JSX.Element => {
    const classes = useStyle();
    const customerDetailsViewClasses = useCustomerDetailsViewStyle();
    const { t } = useTranslation(['customer-details-view']);

    const additionalMenu = useMemo(
        () => generateCustomerDetailsViewAdditionalMenu(t, openChangeNotification),
        [t]
    );

    const areSomeValuesInTheContractDetails = useMemo(() => {
        if (!data) {
            return false;
        }
        return Boolean(
            data.phoneNumber ||
                data.phoneNumber2 ||
                data.mobilePhone ||
                data.fax ||
                data.email ||
                data.email2
        );
    }, [data]);

    const areSomeValuesInTheTable = useMemo(() => {
        if (!data) {
            return false;
        }
        return Boolean(
            data.account ||
                data.account2 ||
                data.accountOwner ||
                data.accountOwner2 ||
                data.bic2 ||
                data.bic ||
                data.blz ||
                data.blz2 ||
                data.iban ||
                data.iban2 ||
                data.bank ||
                data.bank2
        );
    }, [data]);

    return (
        <>
            <Grid item container className={classes.detailsCardContainer}>
                <Grid
                    container
                    item
                    justify='flex-end'
                    className={classes.additionalMenuButtonContainer}
                >
                    <ThemeNestedMenu
                        menuItems={additionalMenu}
                        buttonClass={classes.additionalMenuButton}
                        buttonIcon={<MenuIcon className={classes.menuIcon} />}
                    />
                </Grid>
                <List className={customerDetailsViewClasses.list}>
                    <ContractDetailsListItem
                        hidden={!data?.name && !data?.name2 && !data?.name3}
                        title='customer-details-view:name'
                        value={
                            <List>
                                {data?.name && <ListItemText>{data?.name}</ListItemText>}
                                {data?.name2 && (
                                    <ListItemText>{data?.name2}</ListItemText>
                                )}
                                {data?.name3 && (
                                    <ListItemText>{data?.name3}</ListItemText>
                                )}
                            </List>
                        }
                    />
                    <ContractDetailsListItem
                        title='customer-details-view:address'
                        hidden={
                            !data?.city &&
                            !data?.postcode &&
                            !data?.street &&
                            !data?.country
                        }
                        value={
                            <ThemeAddress
                                street={data?.street}
                                country={data?.country}
                                postcode={data?.postcode}
                                city={data?.city}
                            />
                        }
                    />
                    {areSomeValuesInTheContractDetails && (
                        <ListItem>
                            <ListItemText data-title>
                                {t('customer-details-view:contactDetails')}
                            </ListItemText>
                        </ListItem>
                    )}
                    <ContractDetailsListItem
                        title='customer-details-view:privatePhone'
                        hidden={!data?.phoneNumber}
                        value={data?.phoneNumber}
                    />
                    <ContractDetailsListItem
                        title='customer-details-view:businessPhone'
                        hidden={!data?.phoneNumber2}
                        value={data?.phoneNumber2}
                    />
                    <ContractDetailsListItem
                        title='customer-details-view:fax'
                        hidden={!data?.fax}
                        value={data?.fax}
                    />
                    <ContractDetailsListItem
                        title='customer-details-view:mobilePhone'
                        hidden={!data?.mobilePhone}
                        value={data?.mobilePhone}
                    />
                    <ContractDetailsListItem
                        title='customer-details-view:privateEmail'
                        hidden={!data?.email}
                        value={data?.email}
                    />
                    <ContractDetailsListItem
                        title='customer-details-view:businessEmail'
                        hidden={!data?.email2}
                        value={data?.email2}
                    />
                </List>
                {areSomeValuesInTheTable && (
                    <>
                        <List className={customerDetailsViewClasses.list}>
                            <ListItemText data-title>
                                {t('customer-details-view:bankDetails')}
                            </ListItemText>
                            <ContractDetailsListItem
                                title='customer-details-view:accountOwner'
                                hidden={!data.accountOwner}
                                value={data.accountOwner}
                            />
                            <ContractDetailsListItem
                                title='customer-details-view:institute'
                                hidden={!data.bank}
                                value={data.bank}
                            />
                            <ContractDetailsListItem
                                title='customer-details-view:accountNo'
                                hidden={!data.account}
                                value={data.account}
                            />
                            <ContractDetailsListItem
                                title='customer-details-view:blz'
                                hidden={!data.blz}
                                value={data.blz}
                            />
                            <ContractDetailsListItem
                                title='customer-details-view:iban'
                                hidden={!data.iban}
                                value={data.iban}
                            />
                            <ContractDetailsListItem
                                title='customer-details-view:bic'
                                hidden={!data.bic}
                                value={data.bic}
                            />
                        </List>
                        <List
                            className={customerDetailsViewClasses.list}
                            style={{ marginTop: 24 }}
                        >
                            <ContractDetailsListItem
                                title='customer-details-view:accountOwner'
                                additionalTitle={{ number: 2 }}
                                hidden={!data.accountOwner2}
                                value={data.accountOwner2}
                            />
                            <ContractDetailsListItem
                                title='customer-details-view:institute'
                                additionalTitle={{ number: 2 }}
                                hidden={!data.bank2}
                                value={data.bank2}
                            />
                            <ContractDetailsListItem
                                title='customer-details-view:accountNo'
                                additionalTitle={{ number: 2 }}
                                hidden={!data.account2}
                                value={data.account2}
                            />
                            <ContractDetailsListItem
                                title='customer-details-view:blz'
                                additionalTitle={{ number: 2 }}
                                hidden={!data.blz2}
                                value={data.blz2}
                            />
                            <ContractDetailsListItem
                                title='customer-details-view:iban'
                                additionalTitle={{ number: 2 }}
                                hidden={!data.iban2}
                                value={data.iban2}
                            />
                            <ContractDetailsListItem
                                title='customer-details-view:bic'
                                additionalTitle={{ number: 2 }}
                                hidden={!data.bic2}
                                value={data.bic2}
                            />
                        </List>
                    </>
                )}
            </Grid>
        </>
    );
};
