import { AbilityActions, AbilitySubjects } from '../constants';

type abilityBuilderFn = (actions: string | string[], subjects: string | string[]) => void;

export function customerAdvisorRoleAccessGenerator(
    can: abilityBuilderFn,
    cannot: abilityBuilderFn
): void {
    can(AbilityActions.see, [
        AbilitySubjects.damages,
        AbilitySubjects.customers,
        AbilitySubjects.contractListProductsDropDown,
        AbilitySubjects.customerConnectionLogo,
        AbilitySubjects.dashboard,
        AbilitySubjects.userManagement,
    ]);
    can(AbilityActions.change, AbilitySubjects.dataBase);
    can(AbilityActions.establish, AbilitySubjects.socketConnection);
    can(AbilityActions.use, AbilitySubjects.notificationCenter);
    can([AbilityActions.create, AbilityActions.change], AbilitySubjects.accountOwner);
    can(
        [AbilityActions.edit, AbilityActions.delete],
        [
            AbilitySubjects.accountOwner,
            AbilitySubjects.admin,
            AbilitySubjects.adminWithExternDamageReport,
            AbilitySubjects.clerk,
            AbilitySubjects.clerkWithExternDamageReport,
        ]
    );

    can([AbilityActions.create, AbilityActions.edit], AbilitySubjects.customerRoleGroup);
    can(AbilityActions.edit, AbilitySubjects.userManagementClientConnectionCard);
    can(AbilityActions.transcode, AbilitySubjects.documents);
    can(AbilityActions.see, AbilitySubjects.umMasterData);
    can(AbilityActions.see, AbilitySubjects.umMasterDataActiveField);
    can([AbilityActions.edit, AbilityActions.create], AbilitySubjects.dummyContract);
    can([AbilityActions.edit, AbilityActions.create], AbilitySubjects.customer);
    can(AbilityActions.see, AbilitySubjects.notificationNewMessageCustomerConnectionForm);

    cannot(AbilityActions.change, [
        AbilitySubjects.admin,
        AbilitySubjects.adminWithExternDamageReport,
        AbilitySubjects.clerk,
        AbilitySubjects.clerkWithExternDamageReport,
    ]);
}
