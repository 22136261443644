import { SelectItem } from 'src/theming';
import { TFunction } from 'i18next';

import {
    CUSTOMER_ROLES,
    EXTERNAL_ROLES,
    INTERNAL_ROLES,
    USER_GROUPS,
} from 'src/shared/constants';
import { AnyAbility } from '@casl/ability';
import { AbilityActions, AbilitySubjects } from 'src/roleAccesses';

export const generateUsersEditFormGroupSelectOptions = (
    t: TFunction,
    ability: AnyAbility,
    accessToEditForm: boolean
): SelectItem[] => [
    ...(ability.can(AbilityActions.create, AbilitySubjects.internalRoleGroup) ||
    ability.can(AbilityActions.edit, AbilitySubjects.internalRoleGroup) ||
    !accessToEditForm
        ? [
              {
                  title: t('user-management:userGroups.internal'),
                  value: USER_GROUPS.INTERNAL,
              },
          ]
        : []),
    ...(ability.can(AbilityActions.create, AbilitySubjects.customerRoleGroup) ||
    ability.can(AbilityActions.edit, AbilitySubjects.customerRoleGroup) ||
    !accessToEditForm
        ? [
              {
                  title: t('user-management:userGroups.customer'),
                  value: USER_GROUPS.CUSTOMER,
              },
          ]
        : []),
];

export const generateUserEditFormRoleSelectOptions = (
    t: TFunction,
    userGroup: string,
    disabled: boolean,
    roleFields: { name: string }[],
    ability: AnyAbility
): SelectItem[] => {
    switch (userGroup) {
        case USER_GROUPS.INTERNAL: {
            return [
                ...(ability.can(AbilityActions.create, AbilitySubjects.adminDomain) ||
                ability.can(AbilityActions.edit, AbilitySubjects.adminDomain)
                    ? [
                          {
                              title: t('user-management:roles.adminDomain'),
                              value: INTERNAL_ROLES.ADMIN_DOMAIN,
                          },
                      ]
                    : []),
                ...(ability.can(AbilityActions.create, AbilitySubjects.adminTechnical) ||
                ability.can(AbilityActions.edit, AbilitySubjects.adminTechnical)
                    ? [
                          {
                              title: t('user-management:roles.adminTechnical'),
                              value: INTERNAL_ROLES.ADMIN_TECHNICAL,
                              disabled:
                                  disabled ||
                                  roleFields.some(
                                      (item) =>
                                          item.name === INTERNAL_ROLES.CUSTOMER_ADVISER
                                  ),
                          },
                      ]
                    : []),
                ...(ability.can(AbilityActions.create, AbilitySubjects.customerAdviser) ||
                ability.can(AbilityActions.edit, AbilitySubjects.customerAdviser)
                    ? [
                          {
                              title: t('user-management:roles.customerAdviser'),
                              value: INTERNAL_ROLES.CUSTOMER_ADVISER,
                              disabled: roleFields.some(
                                  (item) => item.name === INTERNAL_ROLES.ADMIN_TECHNICAL
                              ),
                          },
                      ]
                    : []),
                ...(ability.can(AbilityActions.create, AbilitySubjects.caBroker) ||
                ability.can(AbilityActions.edit, AbilitySubjects.caBroker)
                    ? [
                          {
                              title: t('user-management:roles.CABroker'),
                              value: INTERNAL_ROLES.CA_BROKER,
                          },
                      ]
                    : []),
            ];
        }
        case USER_GROUPS.EXTERNAL: {
            return [
                {
                    title: t('user-management:roles.insurance'),
                    value: EXTERNAL_ROLES.INSURANCE,
                },
                {
                    title: t('user-management:roles.supplier'),
                    value: EXTERNAL_ROLES.SUPPLIER,
                },
                {
                    title: t('user-management:roles.presentation'),
                    value: EXTERNAL_ROLES.PRESENTATION,
                },
            ];
        }
        case USER_GROUPS.CUSTOMER: {
            const accessRoles = [];
            if (
                ability.can(AbilityActions.create, AbilitySubjects.accountOwner) ||
                (ability.can(AbilityActions.edit, AbilitySubjects.accountOwner) &&
                    disabled)
            ) {
                accessRoles.push({
                    title: t('user-management:roles.accountOwner'),
                    value: CUSTOMER_ROLES.ACCOUNT_OWNER,
                    disabled,
                });
            }
            if (
                ability.can(AbilityActions.create, AbilitySubjects.admin) ||
                (ability.can(AbilityActions.edit, AbilitySubjects.admin) && disabled)
            ) {
                accessRoles.push({
                    title: t('user-management:roles.administrator'),
                    value: CUSTOMER_ROLES.ADMINISTRATOR,
                    disabled: !ability.can(AbilityActions.create, AbilitySubjects.admin)
                        ? disabled
                        : false,
                });
            }
            if (
                ability.can(
                    AbilityActions.create,
                    AbilitySubjects.adminWithExternDamageReport
                ) ||
                (ability.can(
                    AbilityActions.edit,
                    AbilitySubjects.adminWithExternDamageReport
                ) &&
                    disabled)
            ) {
                accessRoles.push({
                    title: t('user-management:roles.administrator_plus'),
                    value: CUSTOMER_ROLES.ADMINISTRATOR_PLUS,
                    disabled: !ability.can(
                        AbilityActions.create,
                        AbilitySubjects.adminWithExternDamageReport
                    )
                        ? disabled
                        : false,
                });
            }
            if (
                ability.can(AbilityActions.create, AbilitySubjects.clerk) &&
                ability.can(
                    AbilityActions.create,
                    AbilitySubjects.clerkWithExternDamageReport
                ) &&
                ability.can(AbilityActions.edit, AbilitySubjects.clerk) &&
                ability.can(
                    AbilityActions.edit,
                    AbilitySubjects.clerkWithExternDamageReport
                )
            ) {
                accessRoles.push(
                    {
                        title: t('user-management:roles.clerk'),
                        value: CUSTOMER_ROLES.CLERK,
                    },
                    {
                        title: t('user-management:roles.clerk_plus'),
                        value: CUSTOMER_ROLES.CLERK_PLUS,
                    }
                );
            }
            return accessRoles;
        }
        default: {
            return [];
        }
    }
};
