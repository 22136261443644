import React, { SetStateAction, Dispatch, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Grid } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import { ThemeCircularProgress } from 'src/theming';
import { useDashboardContext } from 'src/shared/contexts';
import {
    isHectorInstance,
    isCeatInstance,
    isHectorDemoInstance,
    isCeatMaklerInstance,
} from 'src/environment';

import { useDashboardActionsStyle } from './contract-dashboard-styles';

interface ContractDashboardEditActionsProps {
    setEditMode: Dispatch<SetStateAction<boolean>>;
}

export const ContractDashboardEditActions = ({
    setEditMode,
}: ContractDashboardEditActionsProps): JSX.Element => {
    const classes = useDashboardActionsStyle();
    const { t } = useTranslation(['common']);

    const {
        editMode,
        handleEditMode,
        isUpdatingLoading,
        handleSaveDivisionSettings,
        handleCloseEditMode,
        handleCancelEditMode,
        isLoadingUpdateDashboardSettings,
        setLoadingUpdateDashboardSettings,
    } = useDashboardContext();

    const onEdit = (): void => {
        handleEditMode();
        setEditMode(true);
    };

    const onCancel = (): void => {
        handleCloseEditMode();
        handleCancelEditMode();
        setEditMode(false);
    };

    const onSave = (): void => {
        handleSaveDivisionSettings();
    };

    useEffect(() => {
        return () => {
            if (isLoadingUpdateDashboardSettings) {
                handleCloseEditMode();
                setEditMode(false);
                setLoadingUpdateDashboardSettings(false);
            }
        };
    }, [isLoadingUpdateDashboardSettings]);

    return (
        <Grid item container className={classes.editModeButtons}>
            {!editMode ? (
                <Button
                    startIcon={
                        !isHectorInstance &&
                        !isCeatInstance &&
                        !isCeatMaklerInstance &&
                        !isHectorDemoInstance ? (
                            <EditIcon />
                        ) : null
                    }
                    endIcon={
                        isHectorInstance ||
                        isCeatInstance ||
                        isCeatMaklerInstance ||
                        isHectorDemoInstance ? (
                            <EditOutlinedIcon />
                        ) : null
                    }
                    onClick={onEdit}
                >
                    {t('common:edit')}
                </Button>
            ) : (
                <>
                    <Button
                        data-mode='edit'
                        disabled={isLoadingUpdateDashboardSettings}
                        startIcon={
                            <>
                                {!isUpdatingLoading && <SaveIcon />}
                                <ThemeCircularProgress
                                    isLoading={isLoadingUpdateDashboardSettings}
                                />
                            </>
                        }
                        onClick={onSave}
                        className={classes.saveButton}
                    >
                        {t('common:save')}
                    </Button>
                    <Button
                        data-mode='edit'
                        disabled={isLoadingUpdateDashboardSettings}
                        onClick={onCancel}
                    >
                        {t('common:cancel')}
                    </Button>
                </>
            )}
        </Grid>
    );
};
