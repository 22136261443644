import { AbilityActions, AbilitySubjects } from '../constants';

export function adminTechnicalRoleAccessGenerator(
    can: (actions: string | string[], subjects: string | string[]) => void
): void {
    can(AbilityActions.see, AbilitySubjects.userManagement);
    can(
        [
            AbilityActions.create,
            AbilityActions.edit,
            AbilityActions.delete,
            AbilityActions.change,
        ],
        [
            AbilitySubjects.adminTechnical,
            AbilitySubjects.adminDomain,
            AbilitySubjects.customerAdviser,
        ]
    );
    can(
        [AbilityActions.create, AbilityActions.edit],
        [AbilitySubjects.internalRoleGroup]
    );
    can(AbilityActions.see, AbilitySubjects.umNotifications);
}
