export enum APP_ROUTES {
    HOME = '/',
    LOGIN = '/login',
    CHANGE_PASSWORD = '/change-password',
    CREATE_PASSWORD = '/create-password',
    FORGOT_PASSWORD = '/forgot-password',
    CONTRACTS = '/contracts',
    DAMAGES = '/damages',
    GLOBAL_SEARCH = '/global-search',
    CONTRACT_DETAILS_VIEW_PAGE = '/contracts/:id',
    DAMAGE_DETAILS_VIEW = '/damages/:id',
    FAQ = '/faq',
    USER_MANAGEMENT = '/user-management',
    PRIVILEGES = '/privileges',
    NOTIFICATIONS = '/notifications',
    CUSTOMERS = '/customers',
    CUSTOMER_DETAILS_VIEW_PAGE = '/customers/:id',
    CUSTOMER_ADD_FORM_PAGE = '/customer-form/:id?',
    DAMAGE_REPORT = '/damage-report',
    DAMAGE_REPORT_RECORD = '/damage-report/:id?',
    NON_USERS_DAMAGE_REPORT_FORM = '/damage-report-form/:link?',
    MASTER_DATA = '/master-data',
    HOME_PDF = '/dashboard-pdf',
    TEST_HOME_PDF = '/dashboard-pdf-test',
    REPORT_CENTER = '/report-center',
    DUMMY_CONTRACT = '/dummy-contract',
    NO_ACCESS = '/no-access',
    MAINTANANCE = '/maintanance',
    TRANSCODE_DOCUMENTS = '/transcode-documents/:customerConnection',
    IMPRINT = '/imprint',
    PRIVACY = '/privacy',
}
