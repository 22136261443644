import { appInstance } from 'src/environment';
import { AppInstances } from 'src/shared/constants';

export const contractUploadDocumentsFormGetSuccessffulMessage = (): string => {
    switch (appInstance) {
        case AppInstances.AON:
        case AppInstances.SGIFFOXXK: {
            return 'contract-details-view:contractDetails.uploadDocument.sentSuccessfully';
        }
        case AppInstances.AON_KOMPOSIT: {
            return 'contract-details-view:contractDetails.uploadDocument.uploadedAndSent';
        }
        default: {
            return null;
        }
    }
};
