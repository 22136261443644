import React, { FC, useMemo } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import { ResponsiveBar, Value } from '@nivo/bar';
import { BoxLegendSvg } from '@nivo/legends';
import { LegenedProps } from '@nivo/bar/dist/nivo-bar.es';

import LoadingIndicator from 'src/shared/loading-indicator';
import { useDashboardContext } from 'src/shared/contexts';
import { NivoTheme } from 'src/theming/theme-nivo/nivo-theme-context';

import { PeriodValue } from '../common';
import { DashboardCard } from '../dashboard-card/dashboard-card';

import numericValueFormatter from '../common/numeric-value-formatter';
import { getColorByKey } from './dashboard-bar-chart-colors';
import { DashboardBarChartTooltip } from './dashboard-bar-chart-tooltip';

interface Props {
    nivoTheme: NivoTheme;
    editMode: boolean;
    years?: number[];
    isShown?: boolean;
    handleChangeShown?: (name: string, value: boolean) => void;
    name?: string;
    amounts: PeriodValue<number>[];
}

interface DamagesExpenseBarChartRecord {
    monthNumber: number;
    month: string;
    values: number[];
}

function getBarSettings(years: number[]): {
    keys: string[];
    legened?: ({ dataFrom: 'indexes' | 'keys' } & LegenedProps)[];
    isInteractive: boolean;
    enableLabel: boolean;
} {
    return {
        isInteractive: years?.length > 1,
        enableLabel: years?.length === 1,
        keys: years?.map((year) => `year${year}`),
        legened:
            years?.length > 1
                ? [
                      {
                          data: years.map((year) => ({
                              id: year,
                              label: year,
                              color: getColorByKey({ id: `year${year}` }),
                          })),
                          anchor: 'top',
                          dataFrom: 'keys',
                          direction: 'row',
                          itemHeight: -50,
                          itemWidth: 80,
                          translateX: 0,
                          symbolSize: 10,
                      },
                  ]
                : [],
    };
}

function mapPeriodValuesToBarChartData(
    periodValues: PeriodValue<number>[]
): DamagesExpenseBarChartRecord[] | undefined | null {
    if (!periodValues) {
        return undefined;
    }

    const map = new Map<number, PeriodValue<number>[]>();
    periodValues.forEach((item) => {
        const collection = map.get(item.month);
        if (!collection) {
            map.set(item.month, [item]);
        } else {
            collection.push(item);
        }
    });

    const chartData: DamagesExpenseBarChartRecord[] = [];
    map.forEach((group, key) => {
        chartData.push({
            monthNumber: key,
            month: new Date(2021, key - 1, 1).toLocaleString(i18n.language, {
                month: 'short',
            }),
            values: group.map((item) => item.value || 0),
            ...group.reduce((acc, item) => {
                acc[`year${item.year}`] = item.value;
                return acc;
            }, {}),
        });
    });

    return chartData.length > 0
        ? chartData.sort((a, b) => (a.monthNumber > b.monthNumber ? 1 : -1))
        : null;
}

const BarLegend = ({ height, legends, width }): JSX.Element => (
    <React.Fragment>
        {legends.map((legend) => (
            <BoxLegendSvg
                key={JSON.stringify(legend.data.map(({ id }) => id))}
                {...legend}
                containerHeight={height}
                containerWidth={width}
            />
        ))}
    </React.Fragment>
);

export const DashbaordDamageExpenseByMonth: FC<Props> = ({
    nivoTheme,
    years,
    editMode,
    isShown,
    handleChangeShown,
    name,
    amounts,
}): JSX.Element => {
    const { t } = useTranslation(['dashboard']);

    const { isGetDashboardDataLoading } = useDashboardContext();

    const data = mapPeriodValuesToBarChartData(amounts);

    const axisYTick = useMemo(() => {
        if (Array.isArray(data) && data.length > 0) {
            const maxValueInBarChart = data.reduce((acc, item) => {
                const maxYearValueByMonth = Math.max(...item.values);
                if (maxYearValueByMonth > acc) {
                    return maxYearValueByMonth;
                }
                return acc;
            }, 0);
            const fiveEqualParts = Math.ceil(maxValueInBarChart / 5);
            if (!fiveEqualParts) {
                return null;
            }
            return Array.from(
                new Set([
                    0,
                    ...new Array(5)
                        .fill(fiveEqualParts)
                        .map((item, index) => {
                            const nextTick = item + fiveEqualParts * index;
                            return nextTick > maxValueInBarChart
                                ? maxValueInBarChart
                                : nextTick;
                        })
                        .filter((item) => item),
                ])
            );
        }
        return [0];
    }, [data]);

    const barSettings = getBarSettings(years);

    return (
        <DashboardCard
            header={t('expense.damageExpenseByMonth')}
            editMode={editMode}
            isShown={isShown}
            handleChangeShown={handleChangeShown}
            name={name}
        >
            <>
                {isGetDashboardDataLoading && <LoadingIndicator />}
                {!isGetDashboardDataLoading && data === null && (
                    <div
                        style={{
                            display: 'flex',
                            height: '100%',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                        }}
                    >
                        {t('emptyMsg')}
                    </div>
                )}
                {!isGetDashboardDataLoading && Array.isArray(data) && (
                    <ResponsiveBar
                        enableLabel={barSettings.enableLabel}
                        isInteractive={barSettings.isInteractive}
                        theme={nivoTheme.theme}
                        colors={getColorByKey}
                        data={data}
                        groupMode='grouped'
                        keys={barSettings.keys}
                        indexBy='month'
                        margin={{ top: 50, right: 30, bottom: 50, left: 100 }}
                        padding={0.3}
                        innerPadding={5}
                        gridYValues={axisYTick || []}
                        axisLeft={{
                            legend: t('expense.damageExpenseByMonthLegend'),
                            legendPosition: 'middle',
                            legendOffset: -80,
                            format: (v: Value) => `${numericValueFormatter(v)} €`,
                            tickValues: axisYTick || [],
                        }}
                        axisBottom={{
                            legend: t('month'),
                            legendPosition: 'middle',
                            legendOffset: 32,
                        }}
                        layers={['grid', 'axes', 'bars', 'markers', BarLegend]}
                        legends={barSettings.legened}
                        labelTextColor={{ theme: 'textColor' }}
                        labelFormat={(v: Value) => `${numericValueFormatter(v)} €`}
                        tooltip={(props) => (
                            <DashboardBarChartTooltip
                                valueFormatter={numericValueFormatter}
                                {...props}
                            />
                        )}
                    />
                )}
            </>
        </DashboardCard>
    );
};
