import {
    isHectorInstance,
    isSGIFFOXXKInstance,
    isAonDigitalInstance,
    isAonMotorInstance,
    isCeatInstance,
    isHectorDemoInstance,
    isCeatMaklerInstance,
} from 'src/environment';

import SettingsIcon from '@material-ui/icons/Settings';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';

export const CurrentLockIcon =
    isHectorInstance ||
    isSGIFFOXXKInstance ||
    isAonDigitalInstance ||
    isAonMotorInstance ||
    isCeatInstance ||
    isCeatMaklerInstance ||
    isHectorDemoInstance
        ? LockOutlinedIcon
        : LockIcon;
export const CurrentLockOpenIcon =
    isHectorInstance ||
    isSGIFFOXXKInstance ||
    isAonDigitalInstance ||
    isAonMotorInstance ||
    isHectorDemoInstance ||
    isCeatInstance ||
    isCeatMaklerInstance
        ? LockOpenOutlinedIcon
        : LockOpenIcon;
export const CurrentSettingsIcon =
    isHectorInstance ||
    isSGIFFOXXKInstance ||
    isAonDigitalInstance ||
    isAonMotorInstance ||
    isHectorDemoInstance ||
    isCeatInstance ||
    isCeatMaklerInstance
        ? SettingsOutlinedIcon
        : SettingsIcon;
