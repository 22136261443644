import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { appInstance } from 'src/environment';
import { AppInstances } from 'src/shared/constants';

import {
    useInputAonMotorStyles,
    useFormControlLabelAonMotorStyles,
    useFormControlAonMotorStyles,
    useAutocompleteAonMotorStyles,
    useAonMotorStyle,
} from './damage-report-form-aon-motor-styles';

import {
    useSgiffoxxkStyle,
    useInputSgiffoxxkStyles,
    useFormControlSgiffoxxkStyles,
    useFormControlLabelSgiffoxxkStyles,
    useAutocompleteSgiffoxxkStyles,
} from './damage-report-form-sgiffoxxk-styles';

import {
    useHectorStyle,
    useInputHectorStyles,
    useFormControlLabelHectorStyles,
    useFormControlHectorStyles,
    useAutocompleteHectorStyles,
} from './damage-report-form-hector-styles';

import {
    useInputAonStyles,
    useFormControlLabelAonStyles,
    useFormControlAonStyles,
    useAutocompleteAonStyles,
    useAonStyle,
} from './damage-report-form-aon-styles';

const inputInstancesStyles = {
    [AppInstances.AON]: useInputAonStyles,
    [AppInstances.AON_KOMPOSIT]: useInputAonStyles,
    [AppInstances.AON_DIGITAL]: useInputAonMotorStyles,
    [AppInstances.AON_MOTOR]: useInputAonMotorStyles,
    [AppInstances.HECTOR_DEMO]: useInputAonMotorStyles,
    [AppInstances.CEAT]: useInputHectorStyles,
    [AppInstances.CEAT_MAKLER]: useInputHectorStyles,
    [AppInstances.HECTOR]: useInputHectorStyles,
    [AppInstances.SGIFFOXXK]: useInputSgiffoxxkStyles,
};

const formControlLabelInstancesStyles = {
    [AppInstances.AON]: useFormControlLabelAonStyles,
    [AppInstances.AON_KOMPOSIT]: useFormControlLabelAonStyles,
    [AppInstances.AON_DIGITAL]: useFormControlLabelAonMotorStyles,
    [AppInstances.AON_MOTOR]: useFormControlLabelAonMotorStyles,
    [AppInstances.HECTOR_DEMO]: useFormControlLabelAonMotorStyles,
    [AppInstances.CEAT]: useFormControlLabelHectorStyles,
    [AppInstances.CEAT_MAKLER]: useFormControlLabelHectorStyles,
    [AppInstances.HECTOR]: useFormControlLabelHectorStyles,
    [AppInstances.SGIFFOXXK]: useFormControlLabelSgiffoxxkStyles,
};

const formControlInstancesStyles = {
    [AppInstances.AON]: useFormControlAonStyles,
    [AppInstances.AON_KOMPOSIT]: useFormControlAonStyles,
    [AppInstances.AON_DIGITAL]: useFormControlAonMotorStyles,
    [AppInstances.AON_MOTOR]: useFormControlAonMotorStyles,
    [AppInstances.HECTOR_DEMO]: useFormControlAonMotorStyles,
    [AppInstances.CEAT]: useFormControlHectorStyles,
    [AppInstances.CEAT_MAKLER]: useFormControlHectorStyles,
    [AppInstances.HECTOR]: useFormControlHectorStyles,
    [AppInstances.SGIFFOXXK]: useFormControlSgiffoxxkStyles,
};

const autocompleteInstancesStyles = {
    [AppInstances.AON]: useAutocompleteAonStyles,
    [AppInstances.AON_KOMPOSIT]: useAutocompleteAonStyles,
    [AppInstances.AON_DIGITAL]: useAutocompleteAonMotorStyles,
    [AppInstances.AON_MOTOR]: useAutocompleteAonMotorStyles,
    [AppInstances.HECTOR_DEMO]: useAutocompleteAonMotorStyles,
    [AppInstances.CEAT]: useAutocompleteHectorStyles,
    [AppInstances.CEAT_MAKLER]: useAutocompleteHectorStyles,
    [AppInstances.HECTOR]: useAutocompleteHectorStyles,
    [AppInstances.SGIFFOXXK]: useAutocompleteSgiffoxxkStyles,
};

const instancesStyles = {
    [AppInstances.AON]: useAonStyle,
    [AppInstances.AON_KOMPOSIT]: useAonStyle,
    [AppInstances.AON_DIGITAL]: useAonMotorStyle,
    [AppInstances.AON_MOTOR]: useAonMotorStyle,
    [AppInstances.HECTOR_DEMO]: useAonMotorStyle,
    [AppInstances.CEAT]: useHectorStyle,
    [AppInstances.CEAT_MAKLER]: useHectorStyle,
    [AppInstances.HECTOR]: useHectorStyle,
    [AppInstances.SGIFFOXXK]: useSgiffoxxkStyle,
};

export const useStyle = (): ClassNameMap => {
    return instancesStyles[appInstance]();
};

export const useFormControlStyles = (): ClassNameMap => {
    return formControlInstancesStyles[appInstance]();
};

export const useFormControlLabelStyles = (): ClassNameMap => {
    return formControlLabelInstancesStyles[appInstance]();
};

export const useAutocompleteStyles = (props: unknown): ClassNameMap => {
    return autocompleteInstancesStyles[appInstance](props);
};

export const useInputStyles = (): ClassNameMap => {
    return inputInstancesStyles[appInstance]();
};
